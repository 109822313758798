/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Box,
  Paper,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useStores } from '../../store/root/root.store';
import {
  formatDate,
  DEFAULT_NO_DATA,
  celsiusToFahrenheit,
  statusEnum,
  motorErrorDecoder,
  getVersionNumber,
  getValueOrDefault
} from '../../utils/ui.utils';
import OperationsAuditBatteryDetails from './battery-state.component';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    backgroundColor: theme.palette.primary.light,
    '& > *': {
      color: theme.palette.inverted.main
    }
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    '& > *': {
      color: theme.palette.inverted.main
    }
  },
  closeButton: {
    color: theme.palette.inverted.main,
    minwidth: 100
  },
  stretch: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[400],
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export const AuditRobotStatePopup = ({ ...props }) => {
  const classes = useStyles();
  const { chaperonePropertyStore, blocksStore, subBlocksStore } = useStores();
  const currentRobot = props.audit_record[0];
  const currentTimestamp = props.audit_record[1];
  const robotStateStamped = props.audit_record[2];
  const pathName = robotStateStamped?.robot_state?.navigation_state?.current_path_state?.path_name;
  let showLiquidSensors = false;

  if (currentRobot !== undefined) {
    const version = getVersionNumber(currentRobot);
    showLiquidSensors = version !== 'sim' && parseInt(version) >= 75;
  }

  // ** Make this dynamic in case of future motor additions
  const motorMsgs = [];
  motorMsgs[0] = motorErrorDecoder(robotStateStamped?.robot_state?.control_state?.motors[0]?.fault);
  motorMsgs[1] = motorErrorDecoder(robotStateStamped?.robot_state?.control_state?.motors[1]?.fault);
  motorMsgs[2] = motorErrorDecoder(robotStateStamped?.robot_state?.control_state?.motors[2]?.fault);

  const wpsStateEnum = [];
  wpsStateEnum[0] = 'Idle State';
  wpsStateEnum[1] = 'Waypoints Recording Runnning';
  wpsStateEnum[2] = 'Waypoints Recording Paused';
  wpsStateEnum[3] = 'Waypoints Recording Done';
  wpsStateEnum[4] = 'Waypoints Execution Runnning';
  wpsStateEnum[5] = 'Waypoints Execution Paused';
  wpsStateEnum[6] = 'Waypoints Execution Done';
  wpsStateEnum[50] = 'Waypoints Recording Suspended';
  wpsStateEnum[120] = 'Streaming Timeout';

  const wiperLevelEnum = [];
  wiperLevelEnum[0] = '0%';
  wiperLevelEnum[1] = '50%';
  wiperLevelEnum[2] = '100%';

  const plowHeightEnum = [];
  plowHeightEnum[0] = 'Down';
  plowHeightEnum[1] = 'Up';

  const plowStateEnum = [];
  plowStateEnum[0] = 'V-Mode';
  plowStateEnum[1] = 'Scoop';
  plowStateEnum[2] = 'Angled Left';
  plowStateEnum[3] = 'Angled Right';
  plowStateEnum[4] = 'Unknown';

  const plowLeftExtensionEnum = [];
  plowLeftExtensionEnum[0] = 'Off';
  plowLeftExtensionEnum[1] = 'On';

  const plowRightExtensionEnum = [];
  plowRightExtensionEnum[0] = 'Off';
  plowRightExtensionEnum[1] = 'On';

  const motorFontSize = 15;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const booleanStatus = (value, activeString, inactiveString) => {
    if (value === true) {
      return activeString;
    }

    if (value === false) {
      return inactiveString;
    }

    return DEFAULT_NO_DATA;
  };

  const motorStatusFormatter = (message, messageInstance, index) =>
    message.length !== index + 1 ? `${messageInstance}, ` : messageInstance;

  const [propertyName, setPropertyName] = useState(DEFAULT_NO_DATA);
  const [blockName, setBlockName] = useState(DEFAULT_NO_DATA);
  const [subBlockName, setSubBlockName] = useState(DEFAULT_NO_DATA);
  const [subRowName, setSubrowName] = useState(DEFAULT_NO_DATA);

  /** Gets the property, block, and sub-block name from IDs */
  const getLocationDataNames = async (currentPathName) => {
    if (currentPathName) {
      const [subrowData, pathData] = currentPathName.split('__');
      const [subrowIndex, subrowDirection] = subrowData.split('_');
      const subrowName = `${subrowIndex}_${subrowDirection}`;
      setSubrowName(subrowName);
      const [, propertyId, blockId, subBlockId] = pathData.split('_');
      const updatedPropertyData = await chaperonePropertyStore.getPropertyById({ id: propertyId });
      const updatedBlockData = await blocksStore.getBlockById({ id: blockId });
      const updatedSubBlockData = await subBlocksStore.getSubBlockById({ id: subBlockId });
      setPropertyName(updatedPropertyData?.results?.name);
      setBlockName(updatedBlockData?.results?.name);
      setSubBlockName(updatedSubBlockData?.results?.name);
    }
  };

  useEffect(() => {
    if (pathName) {
      getLocationDataNames(pathName);
    }
  }, [pathName]);

  return (
    <Dialog
      className={classes.root}
      maxWidth="xl"
      fullScreen={fullScreen}
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.title} id="responsive-dialog-title">
        <div style={{ fontWeight: 'bold' }}>{`Robot State - ${currentRobot} - ${formatDate(currentTimestamp)}`}</div>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={props.onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText component="div">
          <Grid container direction="row" spacing={3} justifyContent="center" alignItems="stretch">
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <Item className={classes.stretch}>
                <Box mt={1}>
                  <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    Robot State
                  </Typography>
                </Box>
                <Box mt={2} mb={2}>
                  <Typography>
                    Waypoint State: <b>{wpsStateEnum[robotStateStamped?.robot_state?.navigation_state?.wps_state] || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    Current Attachment: <b>{robotStateStamped?.robot_state?.control_state?.tool_type || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    Free Storage:{' '}
                    <b>
                      {robotStateStamped?.robot_state?.deployment_state?.free_storage
                        ? `${robotStateStamped?.robot_state?.deployment_state?.free_storage}%`
                        : DEFAULT_NO_DATA}
                    </b>
                  </Typography>
                  <Typography>
                    Number of Batteries:{' '}
                    <b>
                      {robotStateStamped?.robot_state?.control_state?.battery_state?.number_of !== undefined
                        ? robotStateStamped?.robot_state?.control_state?.battery_state?.number_of
                        : DEFAULT_NO_DATA}
                    </b>
                  </Typography>
                  <Typography>
                    Battery Percentage:{' '}
                    <b>
                      {robotStateStamped?.robot_state?.control_state?.battery_state?.percentage !== undefined
                        ? robotStateStamped?.robot_state?.control_state?.battery_state?.percentage
                        : `${DEFAULT_NO_DATA} `}
                      %
                    </b>
                  </Typography>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <Item className={classes.stretch}>
                <Box mt={1}>
                  <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    Location State
                  </Typography>
                </Box>
                <Box mt={2} mb={2}>
                  <Typography>
                    WPS State: <b>{robotStateStamped?.robot_state?.navigation_state?.wps_state}</b>
                  </Typography>
                  <Typography>
                    Property: <b>{propertyName}</b>
                  </Typography>
                  <Typography>
                    Block: <b>{blockName}</b>
                  </Typography>
                  <Typography>
                    SubBlock: <b>{subBlockName}</b>
                  </Typography>
                  <Typography>
                    Subrow: <b>{subRowName}</b>
                  </Typography>
                  <Typography>
                    Path type: <b>{robotStateStamped?.robot_state?.navigation_state?.current_path_state?.path_type}</b>
                  </Typography>
                  <Typography>
                    GPS Fix Status: <b>{robotStateStamped?.robot_state?.location_state?.gps_fix_status || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    Heading Degrees:{' '}
                    <b>
                      {((robotStateStamped?.robot_state?.location_state?.heading_radian * 180) / 3.14)
                        .toFixed(2)
                        .toLocaleString(undefined, {
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4
                        }) || `${DEFAULT_NO_DATA} `}
                      °
                    </b>
                  </Typography>
                  <Typography>
                    Linear Speed:{' '}
                    <b>
                      {robotStateStamped?.robot_state?.location_state?.linear_speed.toFixed(2) !== undefined
                        ? robotStateStamped?.robot_state?.location_state?.linear_speed.toFixed(2)
                        : `${DEFAULT_NO_DATA} `}
                      m/s
                    </b>
                  </Typography>
                  <Typography>
                    Angular Speed:{' '}
                    <b>
                      {robotStateStamped?.robot_state?.location_state?.angular_speed.toFixed(2) !== undefined
                        ? robotStateStamped?.robot_state?.location_state?.angular_speed.toFixed(2)
                        : `${DEFAULT_NO_DATA} `}
                      rad/s
                    </b>
                  </Typography>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <Item className={classes.stretch}>
                <Box mt={1}>
                  <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    Peripherals
                  </Typography>
                </Box>
                <Box mt={2} mb={2}>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={6}>
                      <Typography>
                        Control Mode: <b>{robotStateStamped?.robot_state?.control_state?.control_mode || DEFAULT_NO_DATA}</b>
                      </Typography>
                      {robotStateStamped?.robot_state?.control_state?.control_mode === 'teleops' ||
                        (robotStateStamped?.robot_state?.control_state?.control_mode === 'autonomous' && (
                          <Typography>
                            User: <b>{robotStateStamped?.robot_state?.network_state?.active_client?.user || DEFAULT_NO_DATA}</b>
                          </Typography>
                        ))}
                      <Typography>
                        Oil Temperature:{' '}
                        <b>
                          {robotStateStamped?.robot_state?.control_state?.oil_temperature !== undefined
                            ? robotStateStamped?.robot_state?.control_state?.oil_temperature
                            : `${DEFAULT_NO_DATA} `}
                          °C
                        </b>
                        {' | '}
                        <b>
                          {robotStateStamped?.robot_state?.control_state?.oil_temperature !== undefined
                            ? celsiusToFahrenheit(robotStateStamped?.robot_state?.control_state?.oil_temperature)
                            : `${DEFAULT_NO_DATA} `}
                          °F
                        </b>
                      </Typography>
                      <Typography>
                        Hydraulic Pressure:{' '}
                        <b>
                          {robotStateStamped?.robot_state?.control_state?.hydraulic_pressure !== undefined
                            ? `${robotStateStamped?.robot_state?.control_state?.hydraulic_pressure} psi`
                            : `${DEFAULT_NO_DATA} `}
                        </b>
                      </Typography>
                      <Typography>
                        Hose Cooling: <b>{robotStateStamped?.robot_state?.control_state?.cooling_mode_enabled ? 'On' : 'Off'}</b>
                      </Typography>
                      <Typography>
                        Base Liquid Sensor:{' '}
                        {showLiquidSensors && robotStateStamped?.robot_state?.control_state?.base_liquid_sensor ? (
                          <b>{robotStateStamped?.robot_state?.control_state?.base_liquid_sensor ? 'NOT LEAKING' : 'LEAKING'}</b>
                        ) : (
                          <b>{DEFAULT_NO_DATA}</b>
                        )}
                      </Typography>
                      <Typography>
                        Tank Liquid Sensor:{' '}
                        {showLiquidSensors && robotStateStamped?.robot_state?.control_state?.tank_liquid_sensor ? (
                          <b>{robotStateStamped?.robot_state?.control_state?.tank_liquid_sensor ? 'LEAKING' : 'NOT LEAKING'}</b>
                        ) : (
                          <b>{DEFAULT_NO_DATA}</b>
                        )}
                      </Typography>
                      <Typography>
                        Lamps: <b>{robotStateStamped?.robot_state?.control_state?.light_is_on ? 'On' : 'Off'}</b>
                      </Typography>
                      <Typography>
                        Beeper: <b>{robotStateStamped?.robot_state?.control_state?.beeper_is_on ? 'On' : 'Off'}</b>
                      </Typography>
                      <Typography>
                        Fan: <b>{robotStateStamped?.robot_state?.control_state?.fan_is_on ? 'On' : 'Off'}</b>
                      </Typography>
                      <Typography>
                        Wiper level:{' '}
                        <b>
                          {wiperLevelEnum[robotStateStamped?.robot_state?.control_state?.wiper_level] !== undefined
                            ? wiperLevelEnum[robotStateStamped?.robot_state?.control_state?.wiper_level]
                            : DEFAULT_NO_DATA}
                        </b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {robotStateStamped?.robot_state?.control_state?.tool_type === 'plow' && (
                        <>
                          <Typography>
                            Plow Height:{' '}
                            <b>
                              {plowHeightEnum[robotStateStamped?.robot_state?.control_state?.snow_state?.plow_height] || DEFAULT_NO_DATA}
                            </b>
                          </Typography>
                          <Typography>
                            Plow State:{' '}
                            <b>{plowStateEnum[robotStateStamped?.robot_state?.control_state?.snow_state?.plow_state] || DEFAULT_NO_DATA}</b>
                          </Typography>
                          <Typography>
                            Plow Left Extension:{' '}
                            <b>
                              {plowLeftExtensionEnum[robotStateStamped?.robot_state?.control_state?.snow_state?.plow_left_extension] ||
                                DEFAULT_NO_DATA}
                            </b>
                          </Typography>
                          <Typography>
                            Plow Right Extension:{' '}
                            <b>
                              {plowRightExtensionEnum[robotStateStamped?.robot_state?.control_state?.snow_state?.plow_right_extension] ||
                                DEFAULT_NO_DATA}
                            </b>
                          </Typography>
                          <Typography>
                            Salter Speed:{' '}
                            <b>
                              {robotStateStamped?.robot_state?.control_state?.snow_state?.salter_percentage !== undefined
                                ? robotStateStamped?.robot_state?.control_state?.snow_state?.salter_percentage
                                : `${DEFAULT_NO_DATA} `}
                              %
                            </b>
                          </Typography>
                        </>
                      )}
                      {robotStateStamped?.robot_state?.control_state?.tool_type?.includes('mower') && (
                        <>
                          <Typography>
                            Deck Offset:{' '}
                            <b>
                              {robotStateStamped?.robot_state?.control_state?.solar_state?.deck_offset !== undefined
                                ? robotStateStamped?.robot_state?.control_state?.solar_state?.deck_offset
                                : `${DEFAULT_NO_DATA} `}
                              cm
                            </b>
                          </Typography>
                          <Typography>
                            Carriage Offset:{' '}
                            <b>
                              {robotStateStamped?.robot_state?.control_state?.solar_state?.frame_offset !== undefined
                                ? robotStateStamped?.robot_state?.control_state?.solar_state?.frame_offset
                                : `${DEFAULT_NO_DATA} `}
                              cm
                            </b>
                          </Typography>
                          <Typography>
                            Blades Running:{' '}
                            <b>
                              {robotStateStamped?.robot_state?.control_state?.solar_state?.blades_running?.toString() !== undefined
                                ? robotStateStamped?.robot_state?.control_state?.solar_state?.blades_running?.toString()
                                : DEFAULT_NO_DATA}
                            </b>
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Item>
            </Grid>
            {motorMsgs.map((object, i) => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={object.id}>
                <Item className={classes.stretch}>
                  <Box mt={1}>
                    <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                      Motor {i + 1} &#40;
                      {robotStateStamped?.robot_state?.control_state?.motors[i]?.id || DEFAULT_NO_DATA}
                      &#41;
                    </Typography>
                  </Box>
                  <Box mt={2} mb={2}>
                    <Typography style={{ fontSize: motorFontSize }}>
                      Motor Type: <b>{robotStateStamped?.robot_state?.control_state?.motors[i]?.motor_type || DEFAULT_NO_DATA}</b>
                    </Typography>
                    <Typography style={{ fontSize: motorFontSize }}>
                      RPM:{' '}
                      <b>
                        {robotStateStamped?.robot_state?.control_state?.motors[i]?.rpm !== undefined
                          ? robotStateStamped?.robot_state?.control_state?.motors[i]?.rpm
                          : DEFAULT_NO_DATA}
                      </b>
                    </Typography>
                    <Typography style={{ fontSize: motorFontSize }}>
                      Current:{' '}
                      <b>
                        {robotStateStamped?.robot_state?.control_state?.motors[i]?.current !== undefined
                          ? robotStateStamped?.robot_state?.control_state?.motors[i]?.current
                          : `${DEFAULT_NO_DATA} `}
                        A
                      </b>
                    </Typography>
                    <Typography style={{ fontSize: motorFontSize }}>
                      Temperature:{' '}
                      <b>
                        {robotStateStamped?.robot_state?.control_state?.motors[i]?.motor_temp || `${DEFAULT_NO_DATA} `}
                        °C
                      </b>
                      {' | '}
                      <b>
                        {celsiusToFahrenheit(robotStateStamped?.robot_state?.control_state?.motors[i]?.motor_temp) || `${DEFAULT_NO_DATA} `}
                        °F
                      </b>
                    </Typography>
                    <Typography style={{ fontSize: motorFontSize }}>
                      Status: <b>{statusEnum[robotStateStamped?.robot_state?.control_state?.motors[i]?.status] || DEFAULT_NO_DATA}</b>
                    </Typography>
                    <Typography style={{ fontSize: motorFontSize }}>
                      {'Errors: '}
                      {motorMsgs[i]?.errors.length ? (
                        motorMsgs[i]?.errors.map((errors, index) => (
                          <b key={errors.id}>{motorStatusFormatter(motorMsgs[i]?.errors, errors, index)}</b>
                        ))
                      ) : (
                        <b>{DEFAULT_NO_DATA}</b>
                      )}
                    </Typography>
                    <Typography style={{ fontSize: motorFontSize }}>
                      {'Faults: '}
                      {!motorMsgs[i]?.faultsTwo.length && !motorMsgs[i]?.faultsOne.length ? (
                        <b>{DEFAULT_NO_DATA}</b>
                      ) : (
                        motorMsgs[i]?.faultsTwo.map((faultsTwo, index) => (
                          <b key={faultsTwo.id}>{motorStatusFormatter(motorMsgs[i]?.faultsTwo, faultsTwo, index)}</b>
                        ))
                      )}
                      {motorMsgs[i]?.faultsTwo.length ? ', ' : ''}
                      {motorMsgs[i]?.faultsOne.map((faultsOne, index) => (
                        <b key={faultsOne.id}>{motorStatusFormatter(motorMsgs[i]?.faultsOne, faultsOne, index)}</b>
                      ))}
                    </Typography>
                    <Typography style={{ fontSize: motorFontSize }}>
                      {'Warnings: '}
                      {motorMsgs[i]?.warnings.length ? (
                        motorMsgs[i]?.warnings.map((warnings, index) => (
                          <b key={warnings.id}>{motorStatusFormatter(motorMsgs[i]?.warnings, warnings, index)}</b>
                        ))
                      ) : (
                        <b>{DEFAULT_NO_DATA}</b>
                      )}
                    </Typography>
                  </Box>
                </Item>
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <Item className={classes.stretch}>
                <Box mt={1}>
                  <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    Bluetooth Adapter
                  </Typography>
                </Box>
                <Box mt={2} mb={2}>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={6}>
                      <Typography>
                        ID: <b>{robotStateStamped?.robot_state?.joystick_state?.bluetooth_adapter[0]?.id || DEFAULT_NO_DATA}</b>
                      </Typography>
                      <Typography>
                        MAC Address:{' '}
                        <b>{robotStateStamped?.robot_state?.joystick_state?.bluetooth_adapter[0]?.mac_address || DEFAULT_NO_DATA}</b>
                      </Typography>
                      <Typography>
                        Manufacturer:{' '}
                        <b>{robotStateStamped?.robot_state?.joystick_state?.bluetooth_adapter[0]?.manufacturer || DEFAULT_NO_DATA}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        ID: <b>{robotStateStamped?.robot_state?.joystick_state?.bluetooth_adapter[1]?.id || DEFAULT_NO_DATA}</b>
                      </Typography>
                      <Typography>
                        MAC Address:{' '}
                        <b>{robotStateStamped?.robot_state?.joystick_state?.bluetooth_adapter[1]?.mac_address || DEFAULT_NO_DATA}</b>
                      </Typography>
                      <Typography>
                        Manufacturer:{' '}
                        <b>{robotStateStamped?.robot_state?.joystick_state?.bluetooth_adapter[1]?.manufacturer || DEFAULT_NO_DATA}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <Item className={classes.stretch}>
                <Box mt={1}>
                  <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    Controller
                  </Typography>
                </Box>
                <Box mt={2} mb={2}>
                  <Typography>
                    Connected: <b>{robotStateStamped?.robot_state?.joystick_state?.connected || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    Valid Command: <b>{robotStateStamped?.robot_state?.joystick_state?.valid_command || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    Connection Strength: <b>{robotStateStamped?.robot_state?.joystick_state?.connection_strength || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    Controller Type: <b>{robotStateStamped?.robot_state?.joystick_state?.controller_type || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    MAC Address: <b>{robotStateStamped?.robot_state?.joystick_state?.info[0] || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    RSSI value not found: <b>{robotStateStamped?.robot_state?.joystick_state?.info[1] || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    Battery Percentage: <b>{robotStateStamped?.robot_state?.joystick_state?.info[2] || DEFAULT_NO_DATA}</b>
                  </Typography>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <Item className={classes.stretch}>
                <Box mt={1}>
                  <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    Safety
                  </Typography>
                </Box>
                <Box mt={2} mb={2}>
                  <Typography>
                    Control Lockout: <b>{booleanStatus(robotStateStamped?.robot_state?.safety_state?.lockoutState, 'On', 'Off')}</b>
                  </Typography>
                  {robotStateStamped?.robot_state?.safety_state?.lockoutState?.sw_estop_lockout_user && (
                    <Typography>Locked Out By:{robotStateStamped?.robot_state?.safety_state?.sw_estop_lockout_user} </Typography>
                  )}
                  <Typography>
                    E-Stop: <b>{booleanStatus(robotStateStamped?.robot_state?.safety_state?.estop_is_on, 'On', 'Off')}</b>
                  </Typography>
                  <Typography>
                    Baseboard: <b>{booleanStatus(robotStateStamped?.robot_state?.safety_state?.baseboard_is_active, 'ACTIVE', 'FLAMED')}</b>
                  </Typography>
                  <Typography>
                    Safety Stop: <b>{robotStateStamped?.robot_state?.safety_state?.safety_stop !== 0 ? 'On' : 'Off'}</b>
                  </Typography>
                  <Typography>
                    Sensing Edges: <b>{booleanStatus(robotStateStamped?.robot_state?.safety_state?.sensing_edge_is_on, 'On', 'Off')}</b>
                  </Typography>
                  <Typography>
                    SW Estop: <b>{booleanStatus(robotStateStamped?.robot_state?.safety_state?.sw_estop_is_on, 'On', 'Off')}</b>
                  </Typography>
                  <Typography>
                    Stale Teleops Commands:{' '}
                    <b>{booleanStatus(robotStateStamped?.robot_state?.safety_state?.teleops_joy_state?.is_stale, 'Yes', 'No')}</b>
                  </Typography>
                  <Typography>
                    Tele-Ops Joy Latency:{' '}
                    <b>
                      {robotStateStamped?.robot_state?.safety_state?.teleops_joy_state?.latency > -1
                        ? (robotStateStamped.robot_state.safety_state.teleops_joy_state.latency * 1000).toFixed(0)
                        : DEFAULT_NO_DATA}
                    </b>
                  </Typography>
                  <Typography>
                    {'Teleops Controller Connected: '}
                    <b>{booleanStatus(robotStateStamped?.robot_state?.safety_state?.teleops_joy_state?.is_connected, 'Yes', 'No')}</b>
                  </Typography>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <Item className={classes.stretch}>
                <Box mt={1}>
                  <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    Network
                  </Typography>
                </Box>
                <Box mt={2} mb={2}>
                  <Typography>
                    Portal Client Count: <b>{robotStateStamped?.robot_state?.network_state?.portal_client_count || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    Latency: <b>{robotStateStamped?.robot_state?.network_state?.iot_robot_latency || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    Download Speed: <b>{robotStateStamped?.robot_state?.network_state?.download_speed || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    Upload Speed: <b>{robotStateStamped?.robot_state?.network_state?.upload_speed || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    Interface Type: <b>{robotStateStamped?.robot_state?.network_state?.interface_type || DEFAULT_NO_DATA}</b>
                  </Typography>
                  <Typography>
                    Web Server is Reachable::{' '}
                    <b>{getValueOrDefault(robotStateStamped?.robot_state?.network_state?.health?.is_portal_online, 'True', 'False')}</b>
                  </Typography>
                  <Typography>
                    Iot Broker is Reachable:{' '}
                    <b>{getValueOrDefault(robotStateStamped?.robot_state?.network_state?.health?.is_iot_online, 'True', 'False')}</b>
                  </Typography>
                  <Typography>
                    Network Speed is Normal:{' '}
                    <b>{getValueOrDefault(robotStateStamped?.robot_state?.network_state?.health?.are_speeds_healthy, 'True', 'False')}</b>
                  </Typography>
                  <Typography>
                    Network is Healthy:{' '}
                    <b>{getValueOrDefault(robotStateStamped?.robot_state?.network_state?.health?.is_healthy, 'True', 'False')}</b>
                  </Typography>
                </Box>
              </Item>
            </Grid>
          </Grid>
          <Grid style={{ marginTop: '20px ' }}>
            <Item>
              <Box mt={1}>
                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                  Batteries {`(# ${robotStateStamped?.robot_state?.control_state?.battery_state?.number_of})`}
                </Typography>
              </Box>
              <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                {robotStateStamped?.robot_state?.control_state?.battery_state?.battery_info?.length > 0 &&
                  robotStateStamped?.robot_state?.control_state?.battery_state?.battery_info?.map((battery) => (
                    <Grid key={battery?.serial_number} item sm={12} md={4}>
                      <OperationsAuditBatteryDetails battery={battery} key={battery?.serial_number} />
                    </Grid>
                  ))}
              </Grid>
            </Item>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.content}>
        <Button className={classes.closeButton} onClick={props.onClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuditRobotStatePopup;
