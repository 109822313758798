import React from 'react';
import { Grid, makeStyles, Typography, Box, Paper, Fade } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { useStores } from '../../store/root/root.store';
import { DEFAULT_NO_DATA, convertKilobits, getValueOrDefault } from '../../utils/ui.utils';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'white'
  }
}));

/**
 * Network details card on Diagnostics page.
 * This card component is responsible for displaying network details including
 * its health status and errors. The component shows data such as internet speed,
 * the number of users connected to the robot through WS and more. Component also
 * manages rendering error messages
 * @returns {Component} React Paper component
 */
export default function NetworkDetails() {
  const classes = useStyles();
  const { autonomyRobotStore } = useStores();

  return (
    <Fade in>
      <Paper elevation={10} className={classes.paper}>
        <Grid container direction="column" justifyContent="center" alignContent="center">
          <Box m={1}>
            <Typography align="center" variant="subtitle1">
              {'Network Health: '}
              <b>
                {getValueOrDefault(
                  autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.health?.is_healthy,
                  'Healthy',
                  'Error',
                  <Typography
                    style={{ display: 'inline', marginTop: '15px' }}
                    title={autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.health?.error_message || 'N/A'}
                  >
                    <WarningIcon fontSize="medium" style={{ color: '#eed202' }} />
                  </Typography>
                )}
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              {'Web Server Status: '}
              <b>
                {getValueOrDefault(
                  autonomyRobotStore.getSelectedRobot()?.robot_state.network_state.health.is_portal_online,
                  'Online',
                  'Offline'
                )}
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              {'IoT Broker Status : '}
              <b>
                {getValueOrDefault(
                  autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.health?.is_iot_online,
                  'Online',
                  'Offline'
                )}
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              {'Internet Connectivity: '}
              <b>
                {getValueOrDefault(
                  autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.health?.are_speeds_healthy,
                  'Normal',
                  'Error',
                  <WarningIcon style={{ color: '#eed202' }} />
                )}
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              {'Interface Type: '}
              <b>{autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.interface_type || DEFAULT_NO_DATA}</b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              {'Download Speed: '}
              <b>
                {autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.download_speed !== null
                  ? convertKilobits(autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.download_speed)
                  : DEFAULT_NO_DATA}
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              {'Upload Speed: '}
              <b>
                {autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.upload_speed !== null
                  ? convertKilobits(autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.upload_speed)
                  : DEFAULT_NO_DATA}
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              {'Last Speed Test: '}
              <b>{autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.last_speed_test || DEFAULT_NO_DATA}</b>
            </Typography>

            <Typography align="center" variant="subtitle1">
              {'Connected Users: '}
              <b>
                {autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.portal_client_count !== null
                  ? autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.portal_client_count
                  : DEFAULT_NO_DATA}
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              {'IoT Broker Latency: '}
              <b>
                {autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.iot_robot_latency !== null &&
                autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.iot_robot_latency > 0
                  ? autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.iot_robot_latency?.toFixed(2)
                  : DEFAULT_NO_DATA}
                ms
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              {'Tele-Ops Latency: '}
              <b>
                {autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.teleOpsJoyState?.joyLatency !== undefined &&
                autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.teleOpsJoyState?.joyLatency !== null &&
                autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.control_mode === 'teleops'
                  ? `${autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.teleOpsJoyState?.joyLatency}ms`
                  : DEFAULT_NO_DATA}
              </b>
            </Typography>
          </Box>
        </Grid>
      </Paper>
    </Fade>
  );
}
