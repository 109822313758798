import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Dialog,
  useMediaQuery,
  useTheme,
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  DialogContent,
  DialogActions,
  makeStyles,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UsbDevicesTable from '../tables/UsbDevicesTable';
import hubRefImage from '../../assets/images/hub-ref.png';
import orinRefImage from '../../assets/images/orin-ref.png';
import LoadingDialog from '../dialogs/loading-dialog.dialog';
import { ChaperoneRobotConnectionService } from '../../services/chaperone/robot-connection.service';
import { useStores } from '../../store/root/root.store';
import ErrorDialogue from '../dialogs/ErrorDialogue';
import CanDevicesTable from '../tables/CanDevicesTable';
import CanBusDiagnosticsTable from '../tables/CanBusDiagnosticsTable';

const useStyles = makeStyles((theme) => ({
  root: { overflow: 'hidden' },
  dialogPaper: {
    width: '100%',
    height: '80%',
    margin: 'auto',
    maxWidth: '1100px',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: '80%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      margin: 0
    }
  },
  dialogContent: { overflow: 'auto', width: '100%', padding: 0 },
  componentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box'
  },
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  },
  imageButton: {
    border: `2px solid ${theme.palette.grey[500]}`,
    margin: theme.spacing(1),
    minWidth: '120px'
  },
  imageContainer: { flex: '1 1 45%', margin: theme.spacing(1) },
  responsiveImage: {
    width: '80%',
    height: '90%',
    maxWidth: '900px',
    display: 'block',
    margin: '0 auto'
  },
  mobileImageCloseButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'white',
    zIndex: 1000
  }
}));
/**
 * UsbDiagnosticsModal - Dialogue for displaying peripheral devices diagnostics
 *
 * This components is responsible for triggering diagnostics on robot, retrieving
 * data, and displaying it in a tabular format.
 * @param {Boolean} show controls component rendering
 * @param {String} serialNumber selected robot serial number
 * @param {Function} handleClose handler function for closing component
 * @returns
 */
const UsbDiagnosticsModal = observer(({ show, serialNumber, handleClose = () => {} }) => {
  const { autonomyRobotStore } = useStores();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userName = localStorage.getItem('username');

  const [value, setValue] = useState('1');
  const [openImages, setOpenImages] = useState({ orin: false, hub: false });
  const [showLoadingDialogue, setShowLoadingDialogue] = useState(false);
  const [showErrorDialogue, setShowErrorDialogue] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [diagnosticsData, setDiagnosticsData] = useState([]);
  const isMountedRef = useRef(null);
  const robotConnectionService = useRef(null);
  const robotConnection = useRef(null);

  useEffect(() => {
    (async () => {
      if (autonomyRobotStore.selectedRobotId) {
        isMountedRef.current = true;
        try {
          if (isMountedRef.current && robotConnection.current === null) {
            console.debug('Connecting to robot ', autonomyRobotStore.selectedRobotId);
            robotConnectionService.current = new ChaperoneRobotConnectionService(
              async () => {
                robotConnectionService?.current?.ros?.cmdRobotService('DEVICE_DIAGNOSTICS', [], (result) => {
                  if (result.error_message === '') {
                    setShowLoadingDialogue(false);
                    setDiagnosticsData(result?.successMessage);
                  } else {
                    setShowLoadingDialogue(false);
                    setErrorMessage(result?.error_message || 'Contact Autonomy');
                    setShowErrorDialogue(true);
                  }
                });
                robotConnection.current = robotConnectionService.current;
              },
              () => {
                console.log('Lost connection to robot');
                if (robotConnection.current) robotConnectionService?.current?.retryConnection();
                robotConnection.current = null;
              },
              serialNumber,
              userName,
              'device_diagnostics'
            );
          }
        } catch (error) {
          console.error('Failed to connect', error);
        }
      }
    })();

    robotConnectionService?.current?.connectToRobot(() => {});
    return () => {
      isMountedRef.current = false;
      if (robotConnectionService.current !== null) {
        robotConnectionService?.current?.destroy();
        robotConnectionService.current = null;
        robotConnection.current = null;
      }
      autonomyRobotStore.clearSelectedRobot();
    }; // eslint-disable-next-line
  }, []);

  const handleImageToggle = (image) => {
    setOpenImages((prev) => ({ ...prev, [image]: !prev[image] }));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog open={show} onClose={handleClose} fullScreen={fullScreen} classes={{ paper: classes.dialogPaper }} scroll="paper" fullWidth>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="settings"
        variant="fullWidth"
        centered
        indicatorColor="secondary"
        textColor="primary"
        style={{ border: '2px solid grey' }}
      >
        <Tab label={<Typography variant="h6">USB Diagnostics</Typography>} value="1" />
        <Tab label={<Typography variant="h6">CAN Diagnostics</Typography>} value="2" />
        <Tab label={<Typography variant="h6">Misc</Typography>} value="3" disabled />
      </Tabs>
      {
        // eslint-disable-next-line no-nested-ternary
        value === '1' ? (
          <DialogContent className={classes.dialogContent}>
            <Box className={classes.componentsContainer}>
              <Box className={classes.buttonsContainer}>
                <Button className={classes.imageButton} onClick={() => handleImageToggle('orin')}>
                  Orin Reference Diagram
                </Button>
                <Button className={classes.imageButton} onClick={() => handleImageToggle('hub')}>
                  USB Hub Reference Image
                </Button>
              </Box>
              {!isMobile && (
                <Box display="flex" justifyContent="center" flexWrap="wrap">
                  {openImages.orin && (
                    <Box className={classes.imageContainer}>
                      <img src={orinRefImage} alt="Orin connection table" className={classes.responsiveImage} />
                    </Box>
                  )}
                  {openImages.hub && (
                    <Box className={classes.imageContainer}>
                      <img src={hubRefImage} alt="Hub connection table" className={classes.responsiveImage} />
                    </Box>
                  )}
                </Box>
              )}
              {isMobile && openImages.orin && (
                <Dialog open onClose={() => handleImageToggle('orin')} fullScreen>
                  <IconButton className={classes.mobileImageCloseButton} onClick={() => handleImageToggle('orin')}>
                    <CloseIcon />
                  </IconButton>
                  <img src={orinRefImage} alt="Orin connection table" style={{ width: '100%' }} />
                </Dialog>
              )}
              {isMobile && openImages.hub && (
                <Dialog open onClose={() => handleImageToggle('hub')} fullScreen>
                  <IconButton className={classes.mobileImageCloseButton} onClick={() => handleImageToggle('hub')}>
                    <CloseIcon />
                  </IconButton>
                  <img src={hubRefImage} alt="Hub connection table" style={{ width: '100%' }} />
                </Dialog>
              )}
              <UsbDevicesTable data={diagnosticsData} />
            </Box>
          </DialogContent>
        ) : value === '2' ? (
          <DialogContent className={classes.dialogContent}>
            <Box className={classes.componentsContainer}>
              <CanBusDiagnosticsTable />
              <CanDevicesTable />
            </Box>
          </DialogContent>
        ) : null
      }
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default UsbDiagnosticsModal;
