import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Grid, Tooltip, Switch, Typography, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react-lite';
import { checkNullParamater, radToDegreeConverter } from '../../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    border: '2px solid grey',
    borderRadius: '4px',
    boxSizing: 'border-box',
    margin: '0',
    padding: '10px 5px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around'
    }
  },
  subContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: '5px'
    }
  }
}));

/**
 * This component is responsible for managing Deviation settings on Manage Robots
 * @param {Boolean} isSelectedRobotConnectedROS Returns true if robot has ROS connection
 * @param {Boolean} isSelectedRobotInUse Returns True if robot's status is IN_USE
 * @param {Object} uiParameters An object containing ui state values to handle user interaction
 * @param {Function} handleUiParametersChange Handles changes to values uiParameters state
 * @param  {Function} setUiParameters State setter for deviation parameters
 * @returns None
 */
const DeviationConfiguration = observer(
  ({ isSelectedRobotConnectedROS, isSelectedRobotInUse, uiParameters, handleUiParametersChange, setUiParameters }) => {
    const classes = useStyles();
    const [headingSlowThresholdInDegree, setHeadingSlowThresholdInDegree] = useState(0);
    const [headingStopThresholdInDegree, setHeadingStopThresholdInDegree] = useState(0);

    useEffect(() => {
      const newHeadingSlowThreshold = radToDegreeConverter(uiParameters.deviationHeadingSlowThreshold);
      const newHeadingStopThreshold = radToDegreeConverter(uiParameters.deviationHeadingStopThreshold);
      setHeadingSlowThresholdInDegree(newHeadingSlowThreshold);
      setHeadingStopThresholdInDegree(newHeadingStopThreshold);
    }, [uiParameters.deviationHeadingStopThreshold, uiParameters.deviationHeadingSlowThreshold]);

    return (
      <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" className={classes.mainContainer}>
        {/* Deviation Check Switch */}
        <Grid item className={classes.subContainer}>
          <Tooltip
            title={<Typography fontSize={30}>When enabled, the robot will stop if it deviates to far from its autonomous path.</Typography>}
          >
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="autonomyNavigationDeviationCheck"
                  checked={uiParameters.autonomyNavigationDeviationCheck}
                  onChange={(event) => {
                    const { name, checked } = event.target;
                    const formattedValue = checked ? 1 : 0;
                    setUiParameters((previous) => ({
                      ...previous,
                      [name]: formattedValue
                    }));
                  }}
                  color="primary"
                />
              }
              labelPlacement="start"
              label="Deviation Check"
              disabled={
                checkNullParamater(uiParameters.autonomyNavigationDeviationCheck) || !isSelectedRobotConnectedROS || isSelectedRobotInUse
              }
              style={{ opacity: !uiParameters.autonomyNavigationDeviationCheck ? '0.5' : '0.7' }}
            />
          </Tooltip>
        </Grid>
        {/* Slow Threshold Input */}
        <Grid item className={classes.subContainer}>
          <Tooltip
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Typography fontSize={30}>
                This determines the distance from the path that the robot needs to deviate in order to start moving at the slow speed.
              </Typography>
            }
          >
            <FormControl variant="outlined">
              <TextField
                name="deviationPositionSlowThreshold"
                required
                value={uiParameters.deviationPositionSlowThreshold}
                id="slow-threshold"
                label="Slow Threshold (m)"
                variant="outlined"
                type="number"
                inputProps={{ step: '0.1' }}
                disabled={!isSelectedRobotConnectedROS || !uiParameters.autonomyNavigationDeviationCheck || isSelectedRobotInUse}
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.autonomyNavigationDeviationCheck && '0.5' }}
                fullWidth
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
        {/* Stop Threshold Input */}
        <Grid item className={classes.subContainer}>
          <Tooltip
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Typography fontSize={30}>
                This determines the distance from the path that the robot needs to deviate in order to stop.
              </Typography>
            }
          >
            <FormControl variant="outlined">
              <TextField
                name="deviationPositionStopThreshold"
                required
                value={uiParameters.deviationPositionStopThreshold}
                id="stop-threshold"
                label="Stop Threshold (m)"
                variant="outlined"
                type="number"
                inputProps={{ step: '0.01' }}
                disabled={!isSelectedRobotConnectedROS || !uiParameters.autonomyNavigationDeviationCheck || isSelectedRobotInUse}
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.autonomyNavigationDeviationCheck && '0.5' }}
                fullWidth
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
        {/* heading slow threshold */}
        <Grid item className={classes.subContainer}>
          <Tooltip title={<Typography fontSize={30}>{`${headingSlowThresholdInDegree} Degrees`}</Typography>}>
            <FormControl variant="outlined">
              <TextField
                required
                name="deviationHeadingSlowThreshold"
                value={uiParameters.deviationHeadingSlowThreshold}
                id="heading-slow-threshold"
                label="Heading Slow Threshold (rad)"
                variant="outlined"
                type="number"
                inputProps={{ step: '0.1' }}
                disabled={!isSelectedRobotConnectedROS || !uiParameters.autonomyNavigationDeviationCheck || isSelectedRobotInUse}
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.autonomyNavigationDeviationCheck && '0.5' }}
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
        {/* heading stop threshold  */}
        <Grid item className={classes.subContainer}>
          <Tooltip title={<Typography fontSize={30}>{`${headingStopThresholdInDegree} Degrees`}</Typography>}>
            <FormControl variant="outlined">
              <TextField
                required
                name="deviationHeadingStopThreshold"
                value={uiParameters.deviationHeadingStopThreshold}
                id="heading-stop-threshold"
                label="Heading Stop Threshold (rad)"
                variant="outlined"
                type="number"
                inputProps={{ step: '0.1' }}
                disabled={!isSelectedRobotConnectedROS || !uiParameters.autonomyNavigationDeviationCheck || isSelectedRobotInUse}
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.autonomyNavigationDeviationCheck && '0.5' }}
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
        {/* deviation position slow speed */}
        <Grid item className={classes.subContainer}>
          <Tooltip
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Typography fontSize={30}>
                This determines the speed at which the robot will move once the slow threshold has been reached
              </Typography>
            }
          >
            <FormControl variant="outlined">
              <TextField
                required
                name="deviationPositionSlowSpeed"
                value={uiParameters.deviationPositionSlowSpeed}
                id="slow-speed"
                label="Slow Speed (m/s)"
                variant="outlined"
                type="number"
                inputProps={{ step: '0.1' }}
                disabled={!isSelectedRobotConnectedROS || !uiParameters.autonomyNavigationDeviationCheck || isSelectedRobotInUse}
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.autonomyNavigationDeviationCheck && '0.5' }}
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
);

export default DeviationConfiguration;
