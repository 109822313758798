import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText,
  makeStyles,
  Box
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store/root/root.store';
import SolarRowsTable from '../tables/solar-rows.table';
import { STATUS_MAP, AVAILABLE } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  mainContainer: {
    position: 'absolute'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center'
  },
  dialogContentText: {
    textAlign: 'center'
  },
  subrowTableContainer: {
    border: '1px solid #2D3142',
    marginTop: '4px',
    padding: '0px'
  }
}));

const PathConfigurationDialog = observer(
  ({
    robot,
    show,
    hide,
    handleClose,
    containerRef,
    blockList,
    subBlockList,
    subBlockPathList,
    setSelectedBlockId,
    setSelectedSubBlockId,
    setSelectedPathType,
    selectedBlock,
    setSelectedBlock,
    selectedSubBlock,
    setSelectedSubBlock,
    currentPathType,
    setCurrentPathType,
    cancelRepeatingTask,
    robotWpsState
  }) => {
    const classes = useStyles();
    const { MultiRobotsStore, applicationStore } = useStores();

    /** Handler for saving block, sub-block, and path type changes */
    const handleSave = async () => {
      if (selectedBlock !== '' && selectedSubBlock !== '' && currentPathType !== '') {
        await cancelRepeatingTask(false);
        setSelectedBlockId(selectedBlock);
        setSelectedSubBlockId(selectedSubBlock);
        setSelectedPathType(currentPathType);
        MultiRobotsStore.setPathType(robot.serialNumber, currentPathType);
        hide();
      } else {
        applicationStore.pushError('Error', 'Please Select Block, Sub-Block, and Path type');
      }
      /* closes dialog */
    };

    return (
      <Dialog
        open={show}
        onClose={() => handleClose()}
        aria-labelledby="edit-block-subblock-pathtype"
        maxWidth="md"
        container={containerRef.current}
        style={{ position: 'absolute' }}
        hideBackdrop
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          Modify Block, Sub-Block, Subrows, and Path-Type
        </DialogTitle>
        <DialogContentText className={classes.dialogContentText}>{robot.serialNumber}</DialogContentText>
        <DialogContent className={classes.dialogContent}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Block</InputLabel>
            <Select
              value={selectedBlock}
              onChange={(e) => {
                if (typeof e.target.value === 'number') {
                  MultiRobotsStore.resetSelectedSolarSubRows(robot.serialNumber);
                  setCurrentPathType('');
                  setSelectedBlock(e.target.value);
                }
              }}
              disabled={STATUS_MAP[robotWpsState] !== AVAILABLE}
            >
              {blockList.results.map(({ id, name, isDisabled }) => (
                <MenuItem key={id} value={id} disabled={isDisabled}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Sub-Block</InputLabel>
            <Select
              value={selectedSubBlock}
              onChange={(e) => {
                if (typeof e.target.value === 'number') {
                  MultiRobotsStore.resetSelectedSolarSubRows(robot.serialNumber);
                  setCurrentPathType('');
                  setSelectedSubBlock(e.target.value);
                }
              }}
              disabled={STATUS_MAP[robotWpsState] !== AVAILABLE}
            >
              {subBlockList.results.map(({ id, name, isDisabled }) => (
                <MenuItem key={id} value={id} disabled={isDisabled}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Path Type</InputLabel>
            <Select
              value={currentPathType}
              onChange={(e) => {
                if (['string', 'number'].includes(typeof e.target.value)) {
                  const pathType = e.target.value === 0 ? null : e.target.value;
                  setCurrentPathType(pathType);
                }
              }}
              disabled={STATUS_MAP[robotWpsState] !== AVAILABLE}
            >
              {subBlockPathList.map(({ id, name, isDisabled }) => (
                <MenuItem key={id} value={id} disabled={isDisabled}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box className={classes.subrowTableContainer}>
            <SolarRowsTable tableSize={15} isRepeatPage selectedSubBlock={selectedSubBlock} selectedPathType={currentPathType} />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose()} variant="outlined" color="primary">
            Close
          </Button>
          <Button onClick={() => handleSave()} variant="outlined" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default PathConfigurationDialog;
