import React, { useState } from 'react';
import { FormControl, FormControlLabel, Grid, Tooltip, Switch, Typography, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react-lite';
import { checkNullParamater } from '../../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    border: '2px solid grey',
    borderRadius: '4px',
    boxSizing: 'border-box',
    margin: '0',
    padding: '10px 5px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around'
    }
  },
  subContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: '5px'
    }
  }
}));

/**
 * This component is responsible for managing stalled Autonomy settings on Manage Robots
 * @param {Boolean} isSelectedRobotConnectedROS Returns true if robot has ROS connection
 * @param {Boolean} isSelectedRobotInUse Returns True if robot's status is IN_USE
 * @param {Object} uiParameters An object containing ui state values to handle user interaction
 * @param {Function} handleUiParametersChange Handles changes to values uiParameters state
 * @returns None
 */
const StalledAutonomyConfigurations = observer(
  ({ isSelectedRobotConnectedROS, isSelectedRobotInUse, uiParameters, handleUiParametersChange }) => {
    const classes = useStyles();
    const [formattedExecutionProgress, setFormattedExecutionProgress] = useState(
      (uiParameters.stuckExecutionProgress * 100).toFixed(1) || 0
    );

    /**
     * Handles formatting stuckExecutionProgress and calling
     * ui changes handler with formatted value
     *
     * @param {Object} event web event attribute
     * @returns None
     */
    const handleExecutionProgressChange = (event) => {
      if (event) {
        const { name, value } = event.target;
        const fractionalValue = value / 100;
        const dataObject = { target: { name, value: fractionalValue } };
        handleUiParametersChange(dataObject, false, true);
      }
    };

    // formats fractional value of stuckExecutionProgress to percentage value
    useState(() => {
      if (!Number.isNaN(uiParameters.stuckExecutionProgress)) {
        setFormattedExecutionProgress((uiParameters.stuckExecutionProgress * 100).toFixed(1));
      }
    }, [uiParameters.stuckExecutionProgress]);

    return (
      <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" className={classes.mainContainer}>
        {/* Stuck Autonomy Check  */}
        <Grid item className={classes.subContainer}>
          <Tooltip title={<Typography fontSize={30}>When enabled, robot will check if autonomy is stalled or stuck.</Typography>}>
            <FormControlLabel
              control={
                <Switch
                  name="stuckAutonomyCheck"
                  checked={uiParameters.stuckAutonomyCheck}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                />
              }
              labelPlacement="start"
              label="Autonomy Stalled Check"
              disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse || checkNullParamater(uiParameters.stuckAutonomyCheck)}
              style={{ opacity: !uiParameters.stuckAutonomyCheck ? '0.5' : '0.7' }}
            />
          </Tooltip>
        </Grid>
        {/* Autonomy Speed Threshold */}
        <Grid item className={classes.subContainer}>
          <Tooltip title={<Typography fontSize={30}>Robot will be considered stuck during autonomy if it exceeds this speed".</Typography>}>
            <FormControl variant="outlined">
              <TextField
                name="stuckSpeedThreshold"
                required
                value={uiParameters.stuckSpeedThreshold}
                id="stuck-speed-threshold"
                label="Speed Threshold (mps)"
                variant="outlined"
                type="number"
                inputProps={{ step: '0.01' }}
                disabled={
                  !uiParameters.stuckAutonomyCheck ||
                  !isSelectedRobotConnectedROS ||
                  isSelectedRobotInUse ||
                  checkNullParamater(uiParameters.stuckSpeedThreshold)
                }
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.stuckSpeedThreshold && '0.5' }}
                fullWidth
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
        {/* Stuck Autonomy Execution Prgress */}
        <Grid item className={classes.subContainer}>
          <Tooltip title={<Typography fontSize={30}>Expected execution progress when robot is stuck.</Typography>}>
            <FormControl variant="outlined">
              <TextField
                name="stuckExecutionProgress"
                required
                value={formattedExecutionProgress}
                id="stuck-execution-progress"
                label="Execution Progress (%)"
                variant="outlined"
                type="number"
                inputProps={{ step: '0.1' }}
                disabled={
                  !uiParameters.stuckAutonomyCheck ||
                  !isSelectedRobotConnectedROS ||
                  isSelectedRobotInUse ||
                  checkNullParamater(uiParameters.stuckExecutionProgress)
                }
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.stuckExecutionProgress && '0.5' }}
                fullWidth
                onChange={(event) => {
                  handleExecutionProgressChange(event);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
        {/* Stuck Autonomy Drive Current Threshold */}
        <Grid item className={classes.subContainer}>
          <Tooltip
            title={
              <Typography fontSize={30}>
                {`Robot is considered stuck when motors draw ${uiParameters.stuckCurrentThreshold || 'NA'} amps for a period of time`}
              </Typography>
            }
          >
            <FormControl variant="outlined">
              <TextField
                required
                name="stuckCurrentThreshold"
                value={uiParameters.stuckCurrentThreshold}
                id="stuck-current-threshold"
                label="Drive Current Threshold (A)"
                variant="outlined"
                type="number"
                inputProps={{ step: '1' }}
                disabled={
                  !uiParameters.stuckAutonomyCheck ||
                  !isSelectedRobotConnectedROS ||
                  isSelectedRobotInUse ||
                  checkNullParamater(uiParameters.stuckCurrentThreshold)
                }
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.stuckCurrentThreshold && '0.5' }}
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
        {/* Stalled robot timeout  */}
        <Grid item className={classes.subContainer}>
          <Tooltip title={<Typography fontSize={30}>{'Timeout forces autonomous operations to stop if robot is stuck for '}</Typography>}>
            <FormControl variant="outlined">
              <TextField
                required
                name="stuckTimeout"
                value={uiParameters.stuckTimeout}
                id="stuck-timeout-period"
                label="Timeout Period (s)"
                variant="outlined"
                type="number"
                inputProps={{ step: '1' }}
                disabled={
                  !uiParameters.stuckAutonomyCheck ||
                  !isSelectedRobotConnectedROS ||
                  isSelectedRobotInUse ||
                  checkNullParamater(uiParameters.stuckTimeout)
                }
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.stuckTimeout && '0.5' }}
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
);

export default StalledAutonomyConfigurations;
