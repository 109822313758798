import React from 'react';
import { Grid, Tooltip, makeStyles, FormControlLabel, Switch, Box, Typography, TextField, InputAdornment } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { checkNullParamater } from '../../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260
  },
  mainContainer: {
    marginTop: '20px',
    boxSizing: 'border-box',
    justifyContent: 'center',
    marginBottom: '20px',
    width: '100%'
  },
  sectionHeaderContainer: { boxSizing: 'border-box', margin: '5px' },
  sectionHeaderText: { fontSize: '1.2rem' },
  settingsContainer: {
    margin: '20px 0px',
    border: '2px solid #999999',
    boxSizing: 'border-box',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    },
    width: '45%'
  },
  switch: {
    boxSizing: 'border-box',
    opacity: '0.5',
    width: '100%',
    justifyContent: 'center',
    marginLeft: '0px',
    borderRight: '2px solid #a8a9ad',
    [theme.breakpoints.down('sm')]: {
      borderRight: '0px'
    }
  },
  rightmostSwitch: { opacity: '0.5', width: '100%', justifyContent: 'space-around', marginLeft: '0px', marginRight: '2px' },
  textInput: {
    marginLeft: '5px ',
    padding: '4px',
    width: '20%'
  }
}));

/**
 * HardwareConfigurations - Hardware configurations component
 *
 * This component is responsible for managing Hardware configuration on robots
 * @param {Object} uiParameters An object containing ui state values to handle user interaction
 * @param {Function} handleUiParametersChange Handles changes to values uiParameters state
 * @param {Boolean} isSelectedRobotConnectedROS Checks if the robot currently being configured has an active ROS connection
 * @param {Object} isSelectedRobotInUse Checks if robot is currently being used for autonomous operations
 * @returns React Component
 */
const HardwareConfigurations = observer(({ isSelectedRobotConnectedROS, isSelectedRobotInUse, uiParameters, handleUiParametersChange }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.mainContainer} container direction="row">
      <Box className={classes.sectionHeaderContainer}>
        <Typography variant="subtitle1" className={classes.sectionHeaderText}>
          Deck Settings
        </Typography>
      </Box>
      {/** Deck Settings */}
      <Grid item xs={12} className={classes.settingsContainer}>
        {/* Enable Deck Potentiometer is broken */}
        <Tooltip title={<Typography fontSize={30}>When enabled, the Potentiometer will be read from.</Typography>}>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Switch
                name="deckPotWorks"
                checked={uiParameters.deckPotWorks}
                onChange={(event) => {
                  handleUiParametersChange(event, true);
                }}
                color="primary"
                style={{ marginLeft: 'auto' }}
              />
            }
            labelPlacement="start"
            label="Deck Poteniometer Works"
            disabled={checkNullParamater(uiParameters.deckPotWorks) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
            className={classes.switch}
          />
        </Tooltip>
        {/* Set Deck Potentiometer offset correction */}
        <Tooltip title={<Typography fontSize={30}>Set Home or Centered offset value for Deck.</Typography>}>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <TextField
                required
                name="deckPotHomeValue"
                value={uiParameters.deckPotHomeValue}
                id="deckPotHomeValue"
                variant="outlined"
                type="number"
                InputProps={{ step: '1', startAdornment: <InputAdornment position="start">cm</InputAdornment>, min: 0, max: 2 }}
                disabled={!isSelectedRobotConnectedROS || !uiParameters.deckPotHomeValue || isSelectedRobotInUse}
                className={classes.textInput}
                style={{
                  opacity: !isSelectedRobotConnectedROS && !uiParameters.deckPotHomeValue && '0.5'
                }}
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            }
            labelPlacement="start"
            label="Deck Poteniometer Home Value"
            disabled={
              checkNullParamater(uiParameters.framePotHomeValue) ||
              !isSelectedRobotConnectedROS ||
              isSelectedRobotInUse ||
              !uiParameters.deckPotWorks
            }
            className={classes.switch}
            style={{ borderRight: '0px', marginRight: '2px' }}
          />
        </Tooltip>
      </Grid>
      <Box className={classes.sectionHeaderContainer}>
        <Typography variant="subtitle1" className={classes.sectionHeaderText}>
          Frame Settings
        </Typography>
      </Box>
      {/** Frame Settings */}
      <Grid item xs={12} className={classes.settingsContainer}>
        {/* Enable Frame Potentiometer is broken  */}
        <Tooltip title={<Typography fontSize={30}>When enabled, the Frame potentiometer will be read from.</Typography>}>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Switch
                name="framePotWorks"
                checked={uiParameters.framePotWorks}
                onChange={(event) => {
                  handleUiParametersChange(event, true);
                }}
                color="primary"
                style={{ marginLeft: 'auto' }}
              />
            }
            labelPlacement="start"
            label="Frame Poteniometer Works"
            disabled={checkNullParamater(uiParameters.framePotWorks) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
            className={classes.switch}
          />
        </Tooltip>
        {/* Set Frame Potentiometer Home Value */}
        <Tooltip title={<Typography fontSize={30}>Set Home or Centered offset value for Frame.</Typography>}>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <TextField
                required
                name="framePotHomeValue"
                value={uiParameters.framePotHomeValue}
                id="framePotHomeValue"
                variant="outlined"
                type="number"
                InputProps={{ step: '1', startAdornment: <InputAdornment position="start">cm</InputAdornment> }}
                disabled={!isSelectedRobotConnectedROS || !uiParameters.framePotHomeValue || isSelectedRobotInUse}
                className={classes.textInput}
                style={{
                  opacity: !isSelectedRobotConnectedROS && !uiParameters.framePotHomeValue && '0.5'
                }}
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            }
            labelPlacement="start"
            label="Frame Poteniometer Home Value"
            disabled={
              checkNullParamater(uiParameters.framePotHomeValue) ||
              !isSelectedRobotConnectedROS ||
              isSelectedRobotInUse ||
              !uiParameters.framePotWorks
            }
            className={classes.switch}
            style={{ borderRight: '0px', marginRight: '2px' }}
          />
        </Tooltip>
      </Grid>
      <Box className={classes.sectionHeaderContainer}>
        <Typography variant="subtitle1" className={classes.sectionHeaderText}>
          Cooling Settings
        </Typography>
      </Box>
      {/** Cooling System Settings */}
      <Grid item xs={12} className={classes.settingsContainer}>
        {/* Enable Cooling Hose */}
        <Tooltip title={<Typography fontSize={30}>When enabled, the Solenoid will bypass the blades during cooling mode.</Typography>}>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Switch
                name="coolingHoseEnabled"
                checked={uiParameters.coolingHoseEnabled}
                onChange={(event) => {
                  handleUiParametersChange(event, true);
                }}
                color="primary"
                style={{ marginLeft: '1px' }}
              />
            }
            labelPlacement="start"
            label="Enable Cooling Hose"
            disabled={checkNullParamater(uiParameters.coolingHoseEnabled) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
            className={classes.switch}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
});

export default HardwareConfigurations;
