import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { hasHighLatency } from '../../utils/ui.utils';

const useStyles = makeStyles(() => ({
  latencyTextLabel: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

/**
 * Sets text colour to red if latency exceeds a threshold
 * @param {Number} latency
 * @returns text colour or an empty string
 */
const formatLatencyColour = (latency, latencyName) => {
  if (hasHighLatency(latency, latencyName)) {
    return '#d32f2f';
  }
  return '';
};

/**
 * Returns indicators for health and high latency readings
 * @param {Number} latency latency reading
 * @returns
 */
const LatencyToolTipComponent = (latency, latencyName) => (
  <Tooltip
    title={
      // eslint-disable-next-line react/jsx-wrap-multilines
      <span>
        <Typography style={{ fontWeight: 'bold', color: `${formatLatencyColour(latency)}` }} display="inline">
          {`${latency}ms`}
        </Typography>
      </span>
    }
  >
    {!hasHighLatency(latency, latencyName) ? (
      <CheckCircleIcon fontSize="small" style={{ display: 'inline', color: 'green' }} />
    ) : (
      <ErrorIcon fontSize="small" style={{ display: 'inline', color: '#d32f2f' }} />
    )}
  </Tooltip>
);

/**
 * LatencyDisplay - Tooltip display component for latencies
 *
 * This component is responsible for displaying various latencies as a tooltip
 * on multi-robots view and as a grid item in single robot view
 * @param {Number} joyLatency Tele-ops joystick latency in ms
 * @param {Number} webSocketLatency Web-socket (round trip) latency in ms
 * @param {Number} serverLatency Client to server latency in ms
 * @param {Number} mqttLatency Mqtt latency (round trip) in ms
 * @returns {Component} React Component
 */
const LatencyDisplay = ({ joyLatency, webSocketLatency, serverLatency, mqttLatency, isSingleViewMode }) => {
  const classes = useStyles();

  return (
    <Box style={{ margin: '2px 0px' }}>
      {!isSingleViewMode ? (
        <Box p={2} color="white" borderRadius={2}>
          <Typography variant="body1">
            {'Teleops Commands: '}
            <Typography style={{ fontWeight: 'bold', color: `${formatLatencyColour(joyLatency, 'joyLatency')}` }} display="inline">
              {`${joyLatency !== -1 ? joyLatency : 0}ms`}
            </Typography>
          </Typography>
          <Typography variant="body1">
            {'Robot Update: '}
            <Typography
              style={{ fontWeight: 'bold', color: `${formatLatencyColour(webSocketLatency, 'webSocketLatency')}` }}
              display="inline"
            >
              {`${webSocketLatency}ms`}
            </Typography>
          </Typography>
          <Typography variant="body1">
            {' Web Server: '}
            <Typography style={{ fontWeight: 'bold', color: `${formatLatencyColour(serverLatency, 'serverLatency')}` }} display="inline">
              {`${serverLatency}ms`}
            </Typography>
          </Typography>
          <Typography variant="body1">
            {'Robot IoT: '}
            <Typography style={{ fontWeight: 'bold', color: `${formatLatencyColour(mqttLatency, 'mqttLatency')}` }} display="inline">
              {`${mqttLatency}ms`}
            </Typography>
          </Typography>
        </Box>
      ) : (
        <Box p={2} borderRadius={2} boxShadow={1}>
          <Typography className={classes.latencyTextLabel} align="" variant="h6">
            Connectivity Latency
          </Typography>
          <Typography className={classes.latencyTextLabel} variant="body1">
            {'Teleops Commands: '}
            {LatencyToolTipComponent(joyLatency, 'joyLatency')}
          </Typography>
          <Typography className={classes.latencyTextLabel} variant="body1">
            {'Robot Update: '}
            {LatencyToolTipComponent(webSocketLatency, 'webSocketLatency')}
          </Typography>
          <Typography className={classes.latencyTextLabel} variant="body1">
            {' Web Server: '}
            {LatencyToolTipComponent(serverLatency, 'serverLatency')}
          </Typography>
          <Typography className={classes.latencyTextLabel} variant="body1">
            {'Robot IoT: '}
            {LatencyToolTipComponent(mqttLatency, 'mqttLatency')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

// define prop types
LatencyDisplay.propTypes = {
  joyLatency: PropTypes.number.isRequired,
  webSocketLatency: PropTypes.number.isRequired,
  serverLatency: PropTypes.number.isRequired,
  mqttLatency: PropTypes.number.isRequired,
  isSingleViewMode: PropTypes.bool.isRequired
};

export default LatencyDisplay;
