import React from 'react';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    textAlign: 'center',
    padding: theme.spacing(2)
  },
  errorIcon: {
    fontSize: 50,
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2)
  },
  messageText: {
    wordBreak: 'break-word'
  }
}));

/**
 * ErrorDialogue - Generic error dialogue
 *
 * @param {Boolean} show controls rendering
 * @param {Object} style custom style prop
 * @param {String} message error message
 * @param {Number} maxWidth custom width prop
 * @param {Object} containerRef reference to parent container
 * @param {Object} backdropProps style for controlling rendering of background elements
 * @returns
 */
const ErrorDialogue = ({ show, style, message, maxWidth, containerRef, backdropProps }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={show}
      style={style}
      BackdropProps={backdropProps}
      container={containerRef === undefined || !containerRef ? null : containerRef.current}
      maxWidth={maxWidth || 'sm'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogContent}>
        <ErrorIcon className={classes.errorIcon} />
        <Typography variant="h6" className={classes.messageText}>
          {message}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorDialogue;
