import React, { Component } from 'react';
import { Grid, Tooltip, makeStyles, FormControlLabel, Switch, Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { checkNullParamater } from '../../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260
  },
  mainContainer: {
    marginTop: '20px',
    boxSizing: 'border-box',
    justifyContent: 'center',
    marginBottom: '20px',
    width: '100%'
  },
  sectionHeaderContainer: { boxSizing: 'border-box', margin: '5px' },
  sectionHeaderText: { fontSize: '1.2rem' },
  settingsContainer: {
    margin: '20px 0px',
    border: '2px solid #999999',
    boxSizing: 'border-box',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    },
    width: '50%'
  },
  switch: {
    opacity: '0.5',
    width: '100%',
    justifyContent: 'space-around',
    marginLeft: '0px',
    borderRight: '2px solid #a8a9ad',
    [theme.breakpoints.down('sm')]: {
      borderRight: '0px'
    }
  },
  rightmostSwitch: { opacity: '0.5', width: '100%', justifyContent: 'space-around', marginLeft: '0px' }
}));

/**
 * This component is responsible for managing System and Miscellaneous settings on Manage Robots
 * @param {Object} uiParameters An object containing ui state values to handle user interaction
 * @param {Function} handleUiParametersChange Handles changes to values uiParameters state
 * @param {Boolean} isSelectedRobotConnectedROS Checks if the robot currently being configured has an active ROS connection
 * @param {Object} isSelectedRobotInUse Checks if robot is currently being used for autonomous operations
 * @returns {Component} React Component
 */
const SystemConfigurations = observer(
  ({
    isSelectedRobotConnectedROS,
    isSelectedRobotInUse,
    uiParameters,
    handleUiParametersChange,
    handleShowDisableZedDiagnosticWarning
  }) => {
    const classes = useStyles();

    return (
      <Grid item xs={12} className={classes.mainContainer} container direction="row">
        <Box className={classes.sectionHeaderContainer}>
          <Typography variant="subtitle1" className={classes.sectionHeaderText}>
            Cameras & Streaming Settings
          </Typography>
        </Box>
        {/** Camera & Streaming Settings */}
        <Grid item xs={12} className={classes.settingsContainer}>
          {/* Enable Zed Diagnostics  */}
          <Tooltip title={<Typography fontSize={30}>When enabled, ZED diagnositc system will be online.</Typography>}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="acceptableZedLevel"
                  checked={uiParameters.acceptableZedLevel === 1}
                  onChange={(event) => {
                    handleShowDisableZedDiagnosticWarning(event.target.checked);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Disable ZED Camera Diagnostic"
              disabled={checkNullParamater(uiParameters.acceptableZedLevel) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
              className={classes.switch}
            />
          </Tooltip>
          {/* Enable Teleops Streaming */}
          <Tooltip title={<Typography fontSize={30}>When enabled, robot will have video streaming on.</Typography>}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="isTeleopsStreamingEnabled"
                  checked={uiParameters.isTeleopsStreamingEnabled}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Enable TeleOps Streaming"
              disabled={checkNullParamater(uiParameters.isTeleopsStreamingEnabled) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
              className={classes.switch}
            />
          </Tooltip>
          {/* Enable low bandwidth mode */}
          <Tooltip title={<Typography fontSize={30}>When enabled, robot will be on low bandwidth mode.</Typography>}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="isLowStreamingEnabled"
                  checked={uiParameters.isLowStreamingEnabled}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Enable Low Bandwidth"
              disabled={
                checkNullParamater(uiParameters.isLowStreamingEnabled) ||
                !isSelectedRobotConnectedROS ||
                isSelectedRobotInUse ||
                !uiParameters.isTeleopsStreamingEnabled
              }
              className={classes.rightmostSwitch}
            />
          </Tooltip>
        </Grid>
        <Box className={classes.sectionHeaderContainer}>
          <Typography variant="subtitle1" className={classes.sectionHeaderText}>
            ROS & Miscellaneous Settings
          </Typography>
        </Box>
        {/** ROS & Misc Settings */}
        <Grid item xs={12} className={classes.settingsContainer}>
          {/* Enable is dev bot  */}
          <Tooltip title={<Typography fontSize={30}>When enabled, Robot wil be swithced to dev mode</Typography>}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="enableDevBot"
                  checked={uiParameters.enableDevBot}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Enable IsDev Bot "
              disabled={checkNullParamater(uiParameters.enableDevBot) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
              className={classes.switch}
            />
          </Tooltip>
          {/* Enable Rosbag Recording */}
          <Tooltip title={<Typography fontSize={30}>When enabled, robot will record Rosbag data.</Typography>}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="enableRosbagRecording"
                  checked={uiParameters.enableRosbagRecording}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Enable Rosbag Recording"
              disabled={checkNullParamater(uiParameters.enableRosbagRecording) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
              className={classes.switch}
            />
          </Tooltip>
          {/* Enable Rosbag Uploading */}
          <Tooltip title={<Typography fontSize={30}>When enabled, robot will upload rosbag data.</Typography>}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="enableRosbagUploading"
                  checked={uiParameters.enableRosbagUploading}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Enable Rosbag Uploading"
              disabled={checkNullParamater(uiParameters.enableRosbagUploading) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
              className={classes.rightmostSwitch}
            />
          </Tooltip>
        </Grid>
        <Box className={classes.sectionHeaderContainer}>
          <Typography variant="subtitle1" className={classes.sectionHeaderText}>
            Sensor & System Settings
          </Typography>
        </Box>
        {/** Sensor & System Settings */}
        <Grid item xs={12} className={classes.settingsContainer}>
          {/* Enable Base Sensor */}
          <Tooltip
            title={<Typography fontSize={30}>When enabled, robot's base sensor for detecting liquid leakage will be active.</Typography>}
          >
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="enableBaseSensor"
                  checked={uiParameters.enableBaseSensor}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: '1px' }}
                />
              }
              labelPlacement="start"
              label="Enable Base Sensor"
              disabled={checkNullParamater(uiParameters.enableBaseSensor) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
              className={classes.switch}
            />
          </Tooltip>
          {/* Enable Tank Sensor */}
          <Tooltip title={<Typography fontSize={30}>When enabled, robot's safety system will monitor for leaks in the tank.</Typography>}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="enableTankSensor"
                  checked={uiParameters.enableTankSensor}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: '1px' }}
                />
              }
              labelPlacement="start"
              label="Enable Tank Sensor"
              disabled={checkNullParamater(uiParameters.enableTankSensor) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
              className={classes.switch}
            />
          </Tooltip>
          {/* Enable ADAS Chaperone */}
          <Tooltip
            title={
              <Typography fontSize={30}>When enabled, robot's safety system will slow or stop robot when infractions occur.</Typography>
            }
          >
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="enableAdasChaperone"
                  checked={uiParameters.enableAdasChaperone}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Enable ADAS Chaperone"
              disabled={checkNullParamater(uiParameters.enableAdasChaperone) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
              className={classes.rightmostSwitch}
            />
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
);

export default SystemConfigurations;
