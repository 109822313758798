/* eslint-disable no-param-reassign */
import { types, flow, getEnv, getSnapshot } from 'mobx-state-tree';
import { ResourceStore } from './resource.store';
import { AutonomyRobot, ToolType } from './models/autonomy-robot.model';
import { deleteRobot, updateRobotAssignee, fetchRobots, getRobotToolTypes } from '../../services/api/robots.service';
import { HEARTBEAT_TIMEOUT, IN_USE, OFFLINE, PROVISIONING, SENSING_EDGES_BYPASSED, CONTROL_LOCKOUT, TELEOPS } from '../../utils/constants';
import { alphaNumericOrder, unixToDateTime } from '../../utils/ui.utils';

const AutonomyRobotStoreInternal = types
  .model('AutonomyRobotsStore', {
    robots: types.array(AutonomyRobot),
    selectedRobotId: types.maybeNull(types.integer),
    selectedRobots: types.array(types.string),
    selectedAuditRobots: types.array(types.string),
    loading: types.boolean,
    toolTypes: types.array(ToolType)
  })
  .views((self) => ({
    isLoading: () => self.loading,
    hasSelected: () => !!self.selectedRobotId,
    getBySerialNumber: (serialNumber) => self.robots.find((robot) => robot.serial_number === serialNumber),
    getById: (id) => self.robots.find((robot) => robot.id === id),
    getSelectedRobot: () => self.robots.find((r) => r.id === self.selectedRobotId) || {},
    getSelectedRobotSerialNumber: () => self.robots.find((r) => r.id === self.selectedRobotId)?.serial_number,
    getToolTypes: () => getSnapshot(self.toolTypes)
  }))
  .actions((self) => {
    let timer;
    const RobotStatusOrder = ['IN_USE', 'WPS_SUSPENDED', 'EXEC_SUSPENDED', 'AVAILABLE', 'OFFLINE'];

    return {
      getApiFetch: flow(function* ({ country, region, property, use_case }) {
        return yield fetchRobots(country, region, property, use_case);
      }),

      onFetchSuccess(results) {
        const sortedResults = results.sort((a, b) => RobotStatusOrder.indexOf(a.status) - RobotStatusOrder.indexOf(b.status));
        self.robots = sortedResults.toSorted(alphaNumericOrder((r) => r.serial_number));
        self.updateRobotsStatus();
        if (!timer) {
          timer = setInterval(() => {
            self.updateRobotsStatus();
          }, 1000);
        }
      },
      updateRobotsStatus() {
        self.robots = self.robots.map((robot) => {
          if (
            !robot.last_status_update_date ||
            (new Date().getTime() - new Date(robot.last_status_update_date).getTime() > HEARTBEAT_TIMEOUT && robot.status !== OFFLINE)
          ) {
            robot.status = OFFLINE;
          }
          return robot;
        });
      },
      getApiCreate: flow(function* f(region) {
        const { RegionsService } = getEnv(self);
        return yield RegionsService.addRobot(region);
      }),
      onCreateSuccess(created) {
        console.log('Robot Added!');
      },
      onCreateError(error) {
        self.throwError(error?.message || 'Failed to save robot');
      },
      createRobot: flow(function* createRobot(robot) {
        return yield self.create(robot);
      }),
      getRobots: flow(function* getRobots(country, region, property, use_case) {
        yield self.fetch({ country, region, property, use_case });
      }),
      setSelectedRobots(robotSerialNumber) {
        const isRobotAddedBefore = self.selectedRobots.find((robot) => robotSerialNumber === robot);
        if (isRobotAddedBefore) {
          self.selectedRobots = self.selectedRobots.filter((robot) => robot !== robotSerialNumber);
        } else {
          self.selectedRobots = [...self.selectedRobots, robotSerialNumber];
        }
      },
      setSelectedAuditRobots(robotSerialNumber) {
        const isRobotAddedBefore = self.selectedAuditRobots.find((robot) => robotSerialNumber === robot);
        if (isRobotAddedBefore) {
          self.selectedAuditRobots = self.selectedAuditRobots.filter((robot) => robot !== robotSerialNumber);
        } else {
          self.selectedAuditRobots = [...self.selectedAuditRobots, robotSerialNumber];
        }
      },
      setSelectedAction(robotSerialNumber, action) {
        const robot = self.robots.find((item) => item.serial_number === robotSerialNumber);
        robot.selected_action = action;
      },
      setAssignee(robotId, assignee) {
        const robot = self.robots.find((r) => r.id === robotId);
        if (robot) {
          robot.assignee = assignee;
        }
      },
      getRobotProperty(serial) {
        const robot = self.getBySerialNumber(serial);
        if (robot) {
          return robot.current_property_id;
        }
        return null;
      },
      resetSelectedAuditRobts() {
        self.selectedAuditRobots = [];
      },
      resetSelectedRobots() {
        self.selectedRobots = [];
      },
      setSelectedRobot(id) {
        self.selectedRobotId = id;
      },
      setAutonomyLockout(robotSerialNumber, lockedout) {
        const robot = self.getBySerialNumber(robotSerialNumber);
        if (robot) {
          robot.autonomy_lockout = lockedout;
        }
      },
      setAutonomyBaseMotionLockout(robotSerialNumber, lockedout) {
        const robot = self.getBySerialNumber(robotSerialNumber);
        if (robot) {
          robot.autonomy_base_motion_lockout = lockedout;
        }
      },
      clearSelectedRobot() {
        self.selectedRobotId = null;
      },
      clearRobots() {
        self.robots = [];
      },
      setError(msg) {
        self.throwError(msg);
      },
      setSortedRobots(sortedRobots) {
        self.robots = sortedRobots;
      },
      updateRobotStatus(robotSerialNumber, iotStatus, user, currentSubsection, robotState = {}, statusTimestamp = null) {
        self.robots.map((robot) => {
          if (robot.serial_number === robotSerialNumber) {
            const safetyStopEngaged =
              robotState?.safety_state?.sensing_edge_bypassed ||
              robotState?.safety_state?.sw_estop_lockout_user !== '' ||
              robotState?.safety_state?.estop_is_on ||
              robotState?.safety_state?.sw_estop_is_on;
            if (
              robot.last_status_update_date &&
              new Date().getTime() - new Date(robot.last_status_update_date).getTime() <= HEARTBEAT_TIMEOUT
            ) {
              if (robotState?.safety_state?.sensing_edge_bypassed) {
                robot.status = SENSING_EDGES_BYPASSED;
              } else if (robotState?.safety_state?.sw_estop_lockout_user !== '') {
                robot.status = CONTROL_LOCKOUT;
                robot.lockoutUser = robotState?.safety_state?.sw_estop_lockout_user?.toString().split('@')[0];
              } else if (robotState?.safety_state?.safety_enabled !== true) {
                robot.status = PROVISIONING;
              } else if (robotState.control_state.control_mode === 'teleops') {
                robot.status = TELEOPS;
              } else if (iotStatus) {
                robot.status = iotStatus;
              }
            } else {
              // disconnected for more than timeout threshold
              robot.status = OFFLINE;
            }
            if (
              robotState?.network_state?.active_client.user !== '' &&
              (iotStatus === IN_USE || robotState.control_state.control_mode === 'teleops')
            ) {
              robot.current_user = robotState?.network_state?.active_client.user;
            } else {
              robot.current_user = null;
            }
            if (currentSubsection) robot.operating_subsection_id = currentSubsection;
            if (statusTimestamp) {
              robot.last_status_update_date = statusTimestamp.toISOString();
              robot.timestamp = robotState.stamp;
            }
            if (robotState?.control_state?.control_mode) {
              let controlMode = robotState?.control_state?.control_mode;
              if (robotState?.control_state?.motors[0]?.rpm !== 0) {
                controlMode = robotState?.control_state?.control_mode;
              } else {
                controlMode = 'idle';
              }
              if (
                robotState?.safety_state?.estop_is_on ||
                robotState?.safety_state?.sensing_edge_is_on ||
                robotState?.safety_state?.sw_estop_is_on
              ) {
                controlMode = 'idle';
              }
              robotState.control_state.control_mode = controlMode;
            }
            if (Object.keys(robotState).length > 0) {
              const today = new Date();
              const motors = [];
              for (let i = 0; i < robotState?.control_state?.motors?.length; i += 1) {
                motors.push({
                  id: robotState?.control_state?.motors[i].id,
                  motor_type: robotState?.control_state?.motors[i].motor_type,
                  rpm: robotState?.control_state?.motors[i].rpm,
                  current: robotState?.control_state?.motors[i].current,
                  motor_temp: robotState?.control_state?.motors[i].motor_temp,
                  mc_temp: robotState?.control_state?.motors[i].mc_temp,
                  status: robotState?.control_state?.motors[i].status,
                  fault: robotState?.control_state?.motors[i].fault,
                  not_operational: robotState?.control_state?.motors[i].status === 0 && !safetyStopEngaged
                });
              }
              const bluetoothAdapter = [];
              for (let i = 0; i < robotState?.joystick_state?.bluetooth_adapter?.length; i += 1) {
                bluetoothAdapter.push({
                  id: robotState?.joystick_state?.bluetooth_adapter[i].id,
                  mac_address: robotState?.joystick_state?.bluetooth_adapter[i].mac_address,
                  manufacturer: robotState?.joystick_state?.bluetooth_adapter[i].manufacturer
                });
              }
              const batteryInfo = [];
              for (let i = 0; i < robotState?.control_state?.battery_state?.battery_info?.length; i += 1) {
                batteryInfo.push({
                  percentage: robotState?.control_state?.battery_state?.battery_info[i]?.percentage,
                  temperature: robotState?.control_state?.battery_state?.battery_info[i]?.temperature,
                  voltage: robotState?.control_state?.battery_state?.battery_info[i]?.voltage,
                  current: robotState?.control_state?.battery_state?.battery_info[i]?.current,
                  type: robotState?.control_state?.battery_state?.battery_info[i]?.type,
                  timestamp: robotState?.control_state?.battery_state?.battery_info[i]?.timestamp,
                  connected: robotState?.control_state?.battery_state?.battery_info[i]?.connected,
                  id: robotState?.control_state?.battery_state?.battery_info[i]?.id,
                  operation_mode: robotState?.control_state?.battery_state?.battery_info[i]?.operation_mode,
                  time_remaining_min: robotState?.control_state?.battery_state?.battery_info[i]?.time_remaining_min,
                  capacity: robotState?.control_state?.battery_state?.battery_info[i]?.capacity,
                  serial_number: robotState?.control_state?.battery_state?.battery_info[i]?.serial_number,
                  state_of_health: robotState?.control_state?.battery_state?.battery_info[i]?.state_of_health,
                  faults: robotState?.control_state?.battery_state?.battery_info[i]?.faults,
                  errors: robotState?.control_state?.battery_state?.battery_info[i]?.errors,
                  warnings: robotState?.control_state?.battery_state?.battery_info[i]?.warnings
                });
              }

              robot.last_ping = today;
              robot.robot_state = {
                navigation_state: {
                  wps_state: robotState?.navigation_state?.wps_state,
                  navigation_state_updated: robotState?.navigation_state?.navigation_state_updated
                },
                location_state: {
                  gps_fix_status: robotState?.location_state?.gps_fix_status,
                  is_localization_stable: robotState?.location_state?.is_localization_stable,
                  latitude: robotState?.location_state?.latitude,
                  longitude: robotState?.location_state?.longitude
                },
                control_state: {
                  control_mode: robotState?.control_state?.control_mode,
                  tool_type: robotState?.control_state?.tool_type,
                  motors,
                  battery_state: {
                    number_of: robotState?.control_state?.battery_state?.number_of,
                    percentage: robotState?.control_state?.battery_state?.percentage,
                    cumulativeCurrent: robotState?.control_state?.battery_state?.current,
                    avgTimeRemaining: robotState?.control_state?.battery_state?.time_remaining_min,
                    battery_info: batteryInfo
                  },
                  solar_state: {
                    blades_running: robotState?.control_state?.solar_state.blades_running,
                    deck_offset: robotState?.control_state?.solar_state.deck_offset,
                    frame_offset: robotState?.control_state?.solar_state.frame_offset
                  },
                  oil_temperature: robotState?.control_state?.oil_temperature,
                  hydraulic_pressure: robotState?.control_state?.hydraulic_pressure,
                  base_liquid_sensor: robotState?.control_state?.base_liquid_sensor,
                  oil_fan_is_on: robotState?.control_state?.oil_fan_is_on,
                  base_fan_is_on: robotState?.control_state?.base_fan_is_on,
                  cooling_mode_enabled: robotState?.control_state?.cooling_mode_enabled,
                  tank_liquid_sensor: robotState?.control_state?.tank_liquid_sensor
                },
                safety_state: {
                  safety_enabled: robotState?.safety_state?.safety_enabled,
                  safety_error_message: robotState?.safety_state?.safety_error_message,
                  estop_is_on: robotState?.safety_state?.estop_is_on,
                  sensing_edge_is_on: robotState?.safety_state?.sensing_edge_is_on,
                  sw_estop_is_on: robotState?.safety_state?.sw_estop_is_on,
                  baseboard_is_active: robotState?.safety_state?.baseboard_is_active,
                  safety_stop: robotState?.safety_state?.safety_stop,
                  sensing_edge_bypassed: robotState?.safety_state?.sensing_edge_bypassed,
                  lockoutState: robotState?.safety_state?.sw_estop_lockout_user
                },
                joystick_state: {
                  connected: robotState?.joystick_state?.connected,
                  valid_command: robotState?.joystick_state?.valid_command,
                  connection_strength: robotState?.joystick_state?.connection_strength,
                  controller_type: robotState?.joystick_state?.controller_type,
                  info: robotState?.joystick_state?.info,
                  bluetooth_adapter: bluetoothAdapter
                },
                deployment_state: {
                  git_update_state: robotState?.deployment_state?.git_update_state,
                  deployment_status: robotState?.deployment_state?.deployment_status,
                  deployment_target: robotState?.deployment_state?.deployment_target,
                  version: robotState?.deployment_state?.version,
                  commit: robotState?.deployment_state?.commit,
                  free_storage: robotState?.deployment_state?.free_storage,
                  branch_deployed: robotState?.deployment_state?.branch_deployed
                },
                network_state: {
                  portal_client_count: robotState?.network_state?.portal_client_count,
                  iot_robot_latency: robotState?.network_state?.iot_robot_latency,
                  download_speed: robotState?.network_state?.download_speed,
                  upload_speed: robotState?.network_state?.upload_speed,
                  active_client: robotState?.network_state?.active_client,
                  is_online: robotState?.network_state?.is_online,
                  upload_throughput: robotState?.network_state?.upload_throughput,
                  download_throughput: robotState?.network_state?.download_throughput,
                  interface_type: robotState?.network_state?.interface_type,
                  last_speed_test: unixToDateTime(robotState?.network_state?.last_speed_test_stamp),
                  health: {
                    is_portal_online: robotState?.network_state?.health?.is_portal_online,
                    is_iot_online: robotState?.network_state?.health?.is_iot_online,
                    are_speeds_healthy: robotState?.network_state?.health?.are_speeds_healthy,
                    is_healthy: robotState?.network_state?.health?.is_healthy,
                    error_message: robotState?.network_state?.health?.error_message
                  }
                }
              };
              let teleOpsData = {};
              if (robotState.safety_state.teleops_joy_state) {
                teleOpsData = {
                  staleTeleopsJoy: robotState?.safety_state?.teleops_joy_state?.is_stale,
                  joyLatency:
                    robotState.safety_state.teleops_joy_state.latency > -1
                      ? Number((robotState.safety_state.teleops_joy_state.latency * 1000).toFixed(0))
                      : 0
                };
                robot.robot_state.safety_state.teleOpsJoyState = teleOpsData;
              }
            }
            return robot;
          }
          return robot;
        });
      },
      deleteRobot: flow(function* f(id) {
        yield deleteRobot(id);
        self.robots = self.robots.filter((r) => r.id !== id);
      }),
      /**
       * Updates the assignee of a robot in the store.
       *
       * @param {Object} robot - The robot object to be updated.
       * @returns {Promise<void>} - A promise that resolves when the robot assignee has been updated successfully.
       */
      updateRobotAssignee: flow(function* f(robot) {
        try {
          const updatedRobot = yield updateRobotAssignee(robot);
          if (updatedRobot) {
            self.setAssignee(robot.id, updatedRobot.results.assignee);
          }
        } catch (error) {
          console.error(`Error updating robot assignee: ${error}`);
        }
      }),
      /**
       * Fetches current robot tool type in use and registers it in store
       * @param {None}  - None
       * @returns {null} - None
       */
      getRobotToolTypes: flow(function* f() {
        try {
          const data = yield getRobotToolTypes();
          if (data) {
            self.toolTypes = data;
          }
        } catch (error) {
          console.error('Failed to fetch tool types');
        }
        return null;
      })
    };
  });

export const AutonomyRobotStore = types.compose(AutonomyRobotStoreInternal, ResourceStore).named('AutonomyRobotStore');
