/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, Typography, Fade } from '@material-ui/core';
import { useStores } from '../../store/root/root.store';
import { formatTimestamp, formatDeviation } from '../../utils/audits.utils';
import { DEFAULT_NO_DATA } from '../../utils/ui.utils';

const AuditDetails = observer(() => {
  const { autonomyAuditReportsStore } = useStores();
  const { selectedRecord } = autonomyAuditReportsStore;
  const isSolarRecord = selectedRecord.s3URI.includes('/subrows/');
  return (
    <Fade in>
      <Grid container direction="row">
        <Grid item xs={6}>
          <Typography align="center">
            Robot: <b>{selectedRecord.serial_number ? selectedRecord.serial_number : DEFAULT_NO_DATA}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="center">
            Status: <b>{selectedRecord.completedSuccessfully === true ? 'Complete' : 'Incomplete'}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="center">
            Average Deviation: <b>{selectedRecord.averageDeviation ? formatDeviation(selectedRecord.averageDeviation) : DEFAULT_NO_DATA}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="center">
            Maximum Deviation: <b>{selectedRecord.maxDeviation ? formatDeviation(selectedRecord.maxDeviation) : DEFAULT_NO_DATA}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="center">
            Date Completed: <b>{selectedRecord.created_at ? formatTimestamp(selectedRecord.created_at) : DEFAULT_NO_DATA}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="center">
            Country: <b>{selectedRecord.country_name ? selectedRecord.country_name : DEFAULT_NO_DATA}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="center">
            Region: <b>{selectedRecord.region_name ? selectedRecord.region_name : DEFAULT_NO_DATA}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="center">
            Property: <b>{selectedRecord.property_name ? selectedRecord.property_name : DEFAULT_NO_DATA}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="center">
            {isSolarRecord ? 'Block: ' : 'Section: '} <b>{selectedRecord.sectionName ? selectedRecord.sectionName : DEFAULT_NO_DATA}</b>
          </Typography>
        </Grid>
        {isSolarRecord && (
          <>
            <Grid item xs={6}>
              <Typography align="center">
                Sub-Block: <b>{selectedRecord.subBlockName ? selectedRecord.subBlockName : DEFAULT_NO_DATA}</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="center">
                Path Type: <b>{selectedRecord.pathType ? selectedRecord.pathType : DEFAULT_NO_DATA}</b>
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <Typography align="center">
            {isSolarRecord ? 'Subrow: ' : 'Subsection: '}
            <b>{selectedRecord.subsectionName ? selectedRecord.subsectionName : DEFAULT_NO_DATA}</b>
          </Typography>
        </Grid>
      </Grid>
    </Fade>
  );
});

export default AuditDetails;
