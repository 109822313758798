import React from 'react';
import { Dialog, CircularProgress, DialogContent, DialogTitle, Typography, makeStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { formatStringForReadbility } from '../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  dialogContent: { textAlign: 'center', paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2), margin: 0 },
  message: { marginTop: theme.spacing(2) },
  icon: { fontSize: 60, transition: 'transform 0.4s ease-in-out' }
}));

/**
 * Dialog to show Progress Status
 * @param {Boolean} open - dialog rendering control
 * @param {String} status - pairing status fail/success
 * @param {String} title - Dialogue title, is optional
 * @param {String} message - dialogue message
 * @returns
 */
const ProgressModal = ({ open, status, message, title }) => {
  const classes = useStyles();
  const renderContent = () => {
    switch (status) {
      case 'loading':
        return <CircularProgress />;
      case 'success':
        return <CheckCircleOutlineIcon style={{ color: 'green' }} className={classes.icon} />;
      case 'error':
        return <HighlightOffIcon style={{ color: 'red' }} className={classes.icon} />;
      default:
        return null;
    }
  };
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography variant="h4" align="center">
          {title ? formatStringForReadbility(title) : formatStringForReadbility(status)}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {renderContent()}
        <Typography variant="h6" className={classes.message}>
          {message}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ProgressModal;
