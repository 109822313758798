// ---------------- ENUMS ----------------

import { LATENCY_NAMES } from './constants';
const { v4: uuidv4 } = require('uuid');

export const DEFAULT_NO_DATA = 'N/A';

export const statusEnum = [];
statusEnum[0] = 'Pre-Operational';
statusEnum[1] = 'Idle';
statusEnum[2] = 'Post-Operational';
statusEnum[3] = 'Run';
statusEnum[4] = 'Fault';

// ---------------- DEV OPERATIONS ----------------

export const isDevMode = process.env.NODE_ENV === 'development';

export const generateUniqueId = () => (new Date().getTime() + Math.random() * 1000).toString(16);

export const envName = process.env.REACT_APP_ENV;
const portalTarget = process.env.REACT_APP_ENV;

// ---------------- HELPER FUNCTIONS ----------------

export function capitalize(str) {
  const splitStr = str?.toLowerCase().split(' ');
  for (let i = 0; i < splitStr?.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr?.join(' ');
}

export function celsiusToFahrenheit(celsius) {
  if (celsius) return (celsius * 9) / 5 + 32;
  return 0;
}

export function hexToDec(hex) {
  return parseInt(hex, 16);
}

export function formatDate(timestamp) {
  return new Date(timestamp).toString().split('(')[0];
}

/**
 * Formats ISO date string in DD/MM/YYYY HH:mm:ss in local time format or returns N/A
 * @param {string} ISODateString
 * @returns {string} Formated date in or N/A
 */
export function formatDateTime(ISODateString) {
  // pad single digits with 0 to keep HH:mm:ss format

  const padNumber = (num) => (num < 10 ? `0${num}` : num.toString());

  if (ISODateString) {
    const date = new Date(ISODateString);

    const day = padNumber(date.getDate());
    const month = padNumber(date.getMonth() + 1); // months are 0 based, ie January = 0, February = 1, etc
    const year = date.getFullYear();
    const hours = padNumber(date.getHours());
    const minutes = padNumber(date.getMinutes());
    const seconds = padNumber(date.getSeconds());

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  return 'N/A';
}

/**
 * Capitalizes the first letter of a string
 */
export function formatStringForReadbility(string) {
  if (string?.length === 2) return string.toUpperCase();
  if (string?.length > 2) {
    const str = string.charAt(0).toUpperCase() + string.slice(1);
    return str.replace(/_/g, ' ');
  }
  if (string?.length === 1) return string.charAt(0).toUpperCase();
  // if (string && string?.includes('_')) {
  //   return string.replace('_', ' ');
  // }
  return string;
}

export function convertKilobits(passedValue) {
  const units = ['Kb', 'Mb', 'Gb', 'Tb', 'Pb'];
  let i = 0;
  let value = passedValue;
  while (value >= 1024 && i < units.length - 1) {
    value /= 1024;
    i += 1;
  }
  return `${value.toFixed(2)} ${units[i]}`;
}

export function getVersionNumber(serialNumber) {
  const regex = 'V\\d\\dS\\d\\d\\d\\d';

  if (serialNumber.match(regex)) {
    return serialNumber.substring(1, 3);
  }
  return 'sim';
}

// ---------------- ERROR MESSAGE FUNCTIONS ----------------

export function findErrorMsgs(errorCodes) {
  const errorMsgs = [
    'Generic Error',
    'Current',
    'Voltage',
    'Temperature',
    'Communication Error',
    'Device Profile Specific',
    'RESERVED (ALWAYS 0)',
    'Manufacturer Specific'
  ];
  const faultTwoMsgs = [
    'Parameter CRC',
    'Current Scaling',
    'Voltage Scaling',
    'Headlight Under Voltage',
    'Torque Sensor',
    'CAN Bus',
    'Hall Stall',
    'Bootloader'
  ];
  const faultOneMsgs = [
    'Controller Over Voltage',
    'Phase Over Current',
    'Current Sensor Calibration',
    'Current Sensor Over Current',
    'Controller Over Temperature',
    'Motor Hall Sensor Fault',
    'Controller Under Voltage',
    'POST Static Gating Test',
    'Network Communication Timeout',
    'Instantaneous Phase Over Current',
    'Motor Over Temperature',
    'Throttle Voltage Outside Range',
    'Instantaneous Controller Over Voltage',
    'Internal Error',
    'POST Dynamic Gating Test',
    'Instantaneous Under Voltage'
  ];
  const warnMsgs = [
    'Communication Timeout',
    'Hall Sensor',
    'Hall Stall',
    'Wheel Speed Sensor',
    'CAN Bus',
    'Hall Illegal Sector',
    'Hall Illegal Transition',
    'Vdc Low Foldback',
    'Vdc High Foldback',
    'Motor Temperature Foldback',
    'Control Temperature Foldback',
    'Low SOC Foldback',
    'High SOC Foldback',
    'I2t Foldback',
    'RESERVED (NOT USED)',
    'BMS timeout'
  ];

  const allMsgs = {
    errors: [],
    faultsTwo: [],
    faultsOne: [],
    allFaults: [],
    warnings: []
  };

  for (let i = 0; i < errorCodes.length; i += 1) {
    const type = errorCodes[i]?.slice(0, 4);
    const code = errorCodes[i]?.slice(4, errorCodes[i].length);

    // Error: erro, Fault Two: ftwo, Fault One: fone, Warning: warn
    if (type === 'erro') allMsgs.errors.push(errorMsgs[code]);
    else if (type === 'ftwo') allMsgs.faultsTwo.push(faultTwoMsgs[code]);
    else if (type === 'fone') allMsgs.faultsOne.push(faultOneMsgs[code]);
    else if (type === 'warn') allMsgs.warnings.push(warnMsgs[code]);
    else allMsgs.push('Unknown error');
  }
  allMsgs.allFaults = allMsgs.faultsTwo.concat(allMsgs.faultsOne);
  return allMsgs;
}

export function motorErrorDecoder(msg) {
  const byteData = [6];
  const allErrors = [];
  let pos = 19;

  // Parses the bytearray passed from the motor controller
  // In the form of: bytearray(b'\x00\x10\x00\x00\x00\x00\x00\x01')
  for (let i = 0; i < 6; i += 1) {
    const sliced = msg?.slice(msg?.indexOf('x', pos) + 1, msg?.indexOf('x', pos) + 3);
    byteData[i] = sliced;
    pos += 4;
  }

  for (let i = 0; i < 16; i += 1) {
    if (i < 8) {
      if ((hexToDec(byteData[0]) >> i) & 1) allErrors.push(`erro${i}`);
      if ((hexToDec(byteData[1]) >> i) & 1) allErrors.push(`ftwo${i}`);
    }
    if ((hexToDec(byteData[3] + byteData[2]) >> i) & 1) allErrors.push(`fone${i}`);
    if ((hexToDec(byteData[5] + byteData[4]) >> i) & 1) allErrors.push(`warn${i}`);
  }
  return findErrorMsgs(allErrors);
}

/**
 * Formats the date in a custom format
 *
 * @param {String} dateTime - The date (typically will be an ISOString) that needs to be reformatted
 * @returns {String} - Template literal of date in the custom format
 */
export function formatDateTimeToISO(dateTime) {
  const d = new Date(dateTime);
  const year = d.getUTCFullYear();
  const month = String(d.getUTCMonth() + 1).padStart(2, '0');
  const day = String(d.getUTCDate()).padStart(2, '0');
  const hours = String(d.getUTCHours()).padStart(2, '0');
  const minutes = String(d.getUTCMinutes()).padStart(2, '0');
  const seconds = String(d.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(d.getUTCMilliseconds()).padStart(3, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

/** Currently being used as keys to render 2 different infraction tables that use the same infraction table component */
export const infractionTableKeys = {
  ALL_INFRACTIONS: 'allInfractions',
  ZONE_INFRACTIONS: 'infractions'
};

/** Custom sorting function to sort users on user list drop down */
export const sortUsersListByFname = (a, b) => {
  const fa = a['custom:firstName'].toLowerCase();
  const fb = b['custom:firstName'].toLowerCase();

  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
};

/**
 *  Returns a compare function for sorting alphabetically.
 *
 *  @param {function} getStringFunc Get's the string from an object
 */

export const alphaNumericOrder = (getStringFunc) => (a, b) => {
  let sa = getStringFunc(a);
  let sb = getStringFunc(b);

  if (!sa && !sb) return 0;
  if (sa && !sb) return -1;
  if (!sa && sb) return 1;

  sa = sa.toLowerCase();
  sb = sb.toLowerCase();

  return sa.localeCompare(sb, 'en', { numeric: true });
};

/** Returns name field of an object given object id */
export const getNameFromId = (id, objArray) => {
  const match = objArray.filter((obj) => obj.id === parseInt(id));
  return match.length !== 0 ? match[0].name : '';
};

/** Coverts unix time stamp to Date time string  */
export const unixToDateTime = (unixStamp) => {
  if (unixStamp) {
    return new Date(unixStamp * 1000).toLocaleString();
  }
  return null;
};

export const radToDegreeConverter = (rad) => (rad * (180 / Math.PI)).toFixed(2);

/** Recieves a react useRef object and object containing ROS parameters and values.
 * modifies refObject to the following structure {paramName: paramValue}
 */
export const assignParamReference = (refObject, paramsObject) => {
  Object.entries(paramsObject).forEach(([name, value]) => {
    refObject.current[name] = value;
  });
};

/** Constructs an array of string values given an object */
export const objectToStringArray = (object, paramAray) => paramAray.map((key) => String(object[key]));

/** Extracts subrow order-index, direction, and sub-block id from csv file name */
export const extractSubRowMeta = (input) => {
  const regex = /^(\d+)_(N|S|E|W)__(?:\d+_)*(\d+)\.csv$/;
  const match = input.match(regex);
  if (match) {
    const orderIndex = parseInt(match[1], 10);
    const direction = match[2];
    const subblockId = parseInt(match[3], 10);

    return { orderIndex, direction, subblockId };
  }
  return null;
};

/** Given minutes, function converts it to readable Hr:Min format */
export const convertMinutesToHoursMinutes = (minutes) => {
  if (minutes > 0) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.floor(minutes % 60).toFixed(0);
    return `${hours}H : ${remainingMinutes}M`;
  }
  return 0;
};

/**
 * Custom structured constructor for CONFIG command payload
 * @param {Object} paramGroupMap An object that organizes ROS parameters based on their groups
 * @param {Object} uiParameters UI state tracker for ROS params on Edit robots
 * @returns payloadObject
 */
export const configPayloadObjectConstructor = (paramGroupMap, uiParameters) =>
  Object.entries(paramGroupMap).reduce((payloadObject, [groupKey, value]) => {
    // Ensure the groupKey exists in the payloadObject
    if (!payloadObject[groupKey]) {
      // eslint-disable-next-line no-param-reassign
      payloadObject[groupKey] = {};
    }

    if (typeof value === 'object') {
      Object.entries(value).forEach(([key, paramsMap]) => {
        // Ensure the key exists under the groupKey (second layer object key)
        if (!payloadObject[groupKey][key]) {
          // eslint-disable-next-line no-param-reassign
          payloadObject[groupKey][key] = {};
        }
        // Populate the payloadObject with ROS parameters
        Object.values(paramsMap).forEach((param) => {
          const rawParamValue = uiParameters[param];
          const paramValue = rawParamValue;
          // eslint-disable-next-line no-param-reassign
          payloadObject[groupKey][key][param] = paramValue;
        });
      });
    }

    return payloadObject;
  }, {});

/**
 * Function returns current date time in a file name suitable format (ie replaces : with _, etc)
 * @returns date string
 */
export const getFileNameSafeDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}${month}${day}_${hours}${minutes}${seconds}`;
};

/**
 * Checks if ROS parameter is null and returns true. Otherwise, function returns false.
 * Return will be used to enable or disable settings for that parameter on Configure/Edit Robots
 * @param {Any} param ROS paramaeter
 * @returns
 */
export const checkNullParamater = (param) => param === null;

/**
 * Function builds topic for mqtt estop and payload
 * @param {Boolean} estop sw-estop state of robot
 * @param {String} user email of user that triggered estop
 * @param {String} serialNumber robot serial number
 * @returns
 */
export const buildTopicAndPayloadForMqttEstop = (estop, user, serialNumber) => {
  const topic = `swap-${portalTarget}/${serialNumber}/software-estop`;
  return [topic, JSON.stringify({ state: estop, toggled_user: user })];
};

/**
 * Returns unique identifiers for react component key attribute
 * @returns {String} unique id
 */
export const generateReactComponentKey = () => uuidv4();

/**
 * Formats inter-row direction in a readable format
 * @param {String} interrowDirection selected inter-row direction
 * @returns {String} returns formatted inter-row direction
 */
export const formatInterrowDirection = (interrowDirection) => {
  if (interrowDirection === 'l-r') {
    return 'Forward';
  }
  if (interrowDirection === 'r-l') {
    return 'Reversed';
  }
  return 'N/A';
};

/**
 * Evaluates various latency readings and returns true if they exceed their threshold
 *
 * @param {Number} latencyReading latency measured in ms
 * @param {String} latencyName latency name
 * @returns {Boolean} True if latency exceeds a threshold and false otherwise
 */
export const hasHighLatency = (latencyReading, latencyName) => {
  if (latencyName === LATENCY_NAMES.MQTT || latencyName === LATENCY_NAMES.WEBSOCKET) {
    return latencyReading > 3000;
  }
  if (latencyName === LATENCY_NAMES.TELEOPS_JOY || latencyName === LATENCY_NAMES.SERVER) {
    return latencyReading > 1000;
  }
  return false;
};

/**
 * Matches true/false values to readable on/off values
 * Used in Diagnostics to show status for flags of boolean type.
 * Icons can be passed as props
 * Example, use to match baseboard state to Active or Flamed status
 * @param {Boolean} value variable value
 * @param {String} onValue String representation when value is True
 * @param {String} offValue String representation when value is False
 * @param {Component} icon Warning or error icon
 * @returns
 */
export const getValueOrDefault = (value, onValue, offValue, icon) => {
  if (value !== null && !icon) {
    return value ? onValue : offValue;
  }
  if (value !== null && icon) {
    return value ? onValue : icon;
  }
  return DEFAULT_NO_DATA;
};
