import { types, flow } from 'mobx-state-tree';
import { ResourceStore } from '../resources/resource.store';
import { Robot, TeleopsMode } from './multi-robots.model';
import { guardedClient } from '../../utils/axios-instance';
import { LINEAR_SPEED, ANGULAR_SPEED, LOOK_AHEAD } from '../../services/chaperone/robot-connection.service';

const MultiRobotsStoreInternal = types
  .model('MultiRobotsStore', {
    robots: types.map(Robot),
    teleOpsEnabled: types.optional(TeleopsMode, { serialNumber: '', status: false })
  })
  .views((self) => ({
    isLoading: () => self.loading,
    getRobotsArrayLenght: () => self.robots.size,
    hasAutonomousRobotRunning: () => {
      // Iterate over robots and check the isAutonomous property
      return Array.from(self.robots.values()).some((robot) => robot.wpsState === 4);
    }
  }))
  .actions((self) => ({
    addRobot(robot) {
      self.robots?.put(robot);
    },
    removeRobot: flow(function* removeRobot(serialNumber) {
      try {
        self.robots?.delete(serialNumber);
      } catch (error) {
        console.error('Failed to remove robot:', error);
      }
    }),
    updateNotificationMessages(serialNumber, message) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), notificationMessage: message.data });
      }
    },
    getRobotStreamToken: flow(function* getRobotStreamToken(serialNumber) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        if (self.robots.get(serialNumber).streamToken) return;
        const response = yield guardedClient.get('/helpers/stream-token', { params: { serialNumber: `d_${serialNumber}` } });
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), streamToken: response.data.results.token });
      }
    }),
    getRobotID(serialNumber) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        const robotId = self.robots.get(serialNumber).id;
        return robotId;
      }
      return null;
    },
    getSelectedPathType(serialNumber) {
      const robotExists = self.robots.has(serialNumber);
      if (robotExists) {
        const pathType = self.robots.get(serialNumber)?.pathType;
        return pathType;
      }
      return '';
    },
    updateDynamicConfigParameters(serialNumber, params = {}) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), ...params });
      }
    },
    updateStaticConfigParameters(serialNumber, params = {}) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        const updatedParams = { ...self.robots.get(serialNumber), ...params };
        // Convert acceptableZedLevel to a number if it's a string
        if (typeof updatedParams.acceptableZedLevel === 'string') {
          updatedParams.acceptableZedLevel = parseFloat(updatedParams.acceptableZedLevel);
        }
        self.robots.set(serialNumber, updatedParams);
      }
    },
    // makes routine updates to webSocketLatency
    updateWebSocketLatency(serialNumber, webSocketLatency) {
      const robotExists = self.robots.has(serialNumber);
      if (robotExists && webSocketLatency) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), webSocketLatency });
      }
    },
    // updates client-server latency
    updateWebServerLatency(serialNumber, serverLatency) {
      const robotExists = self.robots.has(serialNumber);
      if (robotExists && serverLatency) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), serverLatency });
      }
    },
    // updates client - robot latency through mqtt protocol
    updateMqttLatency(serialNumber, mqttLatency) {
      const robotExists = self.robots.has(serialNumber);
      if (robotExists && mqttLatency) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), mqttLatency });
      }
    },
    updateState(serialNumber, robotStateStamped) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        const robotControlState = robotStateStamped.robot_state.control_state;
        const robotLocationState = robotStateStamped.robot_state.location_state;
        self.setLocation(serialNumber, robotLocationState);
        self.setMotorsState(serialNumber, robotControlState.motors);
        self.robots.set(serialNumber, {
          ...self.robots.get(serialNumber),
          robotStateStamp: robotStateStamped.stamp,
          lastRobotStateReceived: Date.now(),
          wpsState: robotStateStamped.robot_state.navigation_state.wps_state,
          previousWpsState: self.robots.get(serialNumber)?.wpsState,
          controlMode: robotControlState.control_mode,
          plowControlMode: robotControlState.snow_state.plow_control_mode,
          salterControlMode: robotControlState.snow_state.salter_control_mode,
          previousControlMode: robotControlState.control_mode,
          lightsState: robotControlState.light_is_on,
          beeperState: robotControlState.beeper_is_on,
          oilFanState: robotControlState.oil_fan_is_on,
          baseFanState: robotControlState.base_fan_is_on,
          wiperState: robotControlState.wiper_is_on,
          hoseCoolingEnabled: robotControlState.cooling_mode_enabled,
          estopState: robotStateStamped.robot_state.safety_state.estop_is_on,
          swEstopState: robotStateStamped.robot_state.safety_state.sw_estop_is_on,
          safetyStop: robotStateStamped?.robot_state?.safety_state?.safety_stop,
          lockoutState: robotStateStamped.robot_state.safety_state.sw_estop_lockout_user,
          sensingEdgeState: robotStateStamped.robot_state?.safety_state?.sensing_edge_is_on,
          plowHeight: robotControlState.snow_state.plow_height,
          plowState: robotControlState.snow_state.plow_state,
          isLawnMowing: robotControlState.solar_state.blades_running,
          batteryLevel: robotControlState.battery_state.percentage,
          numberOfBatteries: robotControlState.battery_state.number_of,
          portalClientCount: robotStateStamped.robot_state.network_state.portal_client_count,
          activeClient: robotStateStamped.robot_state.network_state.active_client,
          safetyEnabled: robotStateStamped.robot_state.safety_state.safety_enabled,
          safetyErrorMessage: robotStateStamped.robot_state.safety_state.safety_error_message,
          attachementType: robotStateStamped?.robot_state?.control_state?.tool_type,
          deckOffset: parseFloat(robotStateStamped?.robot_state?.control_state?.solar_state.deck_offset),
          frameOffset: parseFloat(robotStateStamped?.robot_state?.control_state?.solar_state.frame_offset),
          bladeRunning: robotStateStamped?.robot_state?.control_state?.solar_state.blades_running,
          isTeleopsStreamingEnabled: robotStateStamped?.robot_state?.deployment_state?.streaming_is_enabled,
          currentRow: robotStateStamped?.robot_state?.navigation_state?.current_path_state?.path_db_id,
          currentRowName: robotStateStamped?.robot_state?.navigation_state?.current_path_state?.path_name,
          autonomyDeviationDistance: robotStateStamped?.robot_state?.navigation_state?.current_path_state?.position_deviation,
          autonomyDeviationHeading: robotStateStamped?.robot_state?.navigation_state?.current_path_state?.heading_deviation_rad,
          sensingEdgeBypassed: robotStateStamped?.robot_state?.safety_state?.sensing_edge_bypassed,
          oilTemperature: robotStateStamped.robot_state?.control_state?.oil_temperature
        });
        let teleOpsData = {};
        if (robotStateStamped.robot_state.safety_state.teleops_joy_state) {
          teleOpsData = {
            staleTeleopsJoy: robotStateStamped.robot_state?.safety_state?.teleops_joy_state?.is_stale,
            joyLatency:
              robotStateStamped.robot_state.safety_state.teleops_joy_state.latency > -1
                ? Number((robotStateStamped.robot_state.safety_state.teleops_joy_state.latency * 1000).toFixed(0))
                : 0
          };
          self.robots.set(serialNumber, {
            ...self.robots.get(serialNumber),
            teleopsJoyStatus: teleOpsData
          });
        }
      }
    },
    updateAutonomyHealthcheckLockout(serialNumber, auotnomyStoppedDeviated) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, {
          ...self.robots.get(serialNumber),
          auotnomyStoppedDeviated
        });
      }
    },
    updateDoneRepeatingMission(serialNumber, isDoneRepeatingMission) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, {
          ...self.robots.get(serialNumber),
          isDoneRepeatingMission,
          autonomyDeviationDistance: -1,
          auotnomyStoppedDeviated: false
        });
      }
    },
    updateDoneRepeatingPath(serialNumber, isDoneRepeatingPath) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, {
          ...self.robots.get(serialNumber),
          isDoneRepeatingPath,
          autonomyDeviationDistance: -1,
          auotnomyStoppedDeviated: false
        });
      }
    },
    updateSpeedLimit(serialNumber, speedLimit) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), speedLimit });
      }
    },
    updatePreviousWpsState(serialNumber, previousWpsState) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), previousWpsState });
      }
    },
    setMotorsState(serialNumber, motors) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        motors.forEach((motor) => {
          self.robots.set(serialNumber, {
            ...self.robots.get(serialNumber),
            motors: self.robots.get(serialNumber).motors.set(motor.id, motor)
          });
        });
      }
    },
    updateLatency(serialNumber, highLatency, latency) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), highLatency, latency });
      }
    },
    updateMqttSwEstopState(serialNumber, swEstopState) {
      const robotExists = self.robots.has(serialNumber);
      if (robotExists) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), swEstopState });
      }
    },
    setLocation(serialNumber, locationState) {
      const currentRobot = self.robots.get(serialNumber);
      if (currentRobot.isFirstLocation) {
        self.robots.set(serialNumber, {
          ...self.robots.get(serialNumber),
          isFirstLocation: false,
          firstLat: locationState.latitude,
          firstLng: locationState.longitude
        });
      }
      if (currentRobot.isFirstHeading) {
        self.robots.set(serialNumber, {
          ...self.robots.get(serialNumber),
          isFirstHeading: false,
          firstHeading: locationState.heading_radian
        });
      }
      self.robots.set(serialNumber, {
        ...self.robots.get(serialNumber),
        currentHeadingRad: locationState.heading_radian,
        lat: locationState.latitude,
        lng: locationState.longitude,
        gpsFixStatus: locationState.gps_fix_status,
        linearVelocity: locationState.linear_speed
      });
      if (locationState.is_localization_stable) {
        self.robots.set(serialNumber, {
          ...self.robots.get(serialNumber),
          stableLocalization: true
        });
      } else {
        self.robots.set(serialNumber, {
          ...self.robots.get(serialNumber),
          stableLocalization: false
        });
      }
    },
    resetRobot(serialNumber) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, {
          ...self.robots.get(serialNumber),
          autonomyDeviationDistance: -1,
          auotnomyStoppedDeviated: false,
          controlMode: 'manual',
          previousControlMode: 'manual',
          bladeRunning: false,
          notificationMessage: ''
        });
      }
    },
    resetUiRobotWpsState(serialNumber) {
      // Needed only if the connection interrupted but otherwise not needed
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, {
          ...self.robots.get(serialNumber),
          wpsState: 0
        });
      }
    },
    setSelectedSolarSubRows(serialNumber, solarSubRows) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), selectedSolarSubRows: solarSubRows });
      }
    },
    resetSelectedSolarSubRows(serialNumber) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), selectedSolarSubRows: [] });
      }
    },
    setTeleopsMode(serialNumber, status) {
      const robotExists = self.robots.has(serialNumber);
      if (robotExists) {
        if (!self.teleOpsEnabled) {
          self.teleOpsEnabled = TeleopsMode.create({ serialNumber, status });
        } else {
          self.teleOpsEnabled.serialNumber = serialNumber;
          self.teleOpsEnabled.status = status;
        }
      }
    },
    setPathType(serialNumber, selectedPathType) {
      const robotExists = self.robots.has(serialNumber);
      if (robotExists) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), pathType: selectedPathType });
      }
    },
    updateMotionParameters(serialNumber, motionParams = {}) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, { ...self.robots.get(serialNumber), ...motionParams });
      }
    },
    resetRobots() {
      self.robots = {};
    },
    resetNavParams(serialNumber) {
      const isRobotExist = self.robots.has(serialNumber);
      if (isRobotExist) {
        self.robots.set(serialNumber, {
          ...self.robots.get(serialNumber),
          linearSpeed: LINEAR_SPEED.default,
          lookAheadDistance: LOOK_AHEAD.default,
          angularSpeed: ANGULAR_SPEED.default
        });
      }
    }
  }));

export const MultiRobotsStore = types.compose(MultiRobotsStoreInternal, ResourceStore).named('MultiRobotsStore');
