export const NOT_STARTED = 'NOT_STARTED';
export const COMPLETED = 'COMPLETED';
export const IN_PROGRESS = 'IN_PROGRESS';

export const SNOW_PLOWING = 'SNOW_PLOWING';
export const NON_SOLAR_LAWN_MOWING = 'NON_SOLAR_LAWN_MOWING';
export const SOLAR_LAWN_MOWING = 'SOLAR_LAWN_MOWING';

export const AVAILABLE = 'AVAILABLE';
export const OFFLINE = 'OFFLINE';
export const IN_USE = 'IN_USE';
export const TELEOPS = 'TELEOPS';
export const WPS_SUSPENDED = 'WPS_SUSPENDED';
export const EXEC_SUSPENDED = 'EXEC_SUSPENDED';
export const PROVISIONING = 'PROVISIONING';
export const UNINTIALIZED = 'UNINTIALIZED';
export const SENSING_EDGES_BYPASSED = 'SENSING_EDGES_BYPASSED';
export const CONTROL_LOCKOUT = 'CONTROL_LOCKOUT';

export const STATUSES = { AVAILABLE: 'Online', OFFLINE: 'Offline' };
export const PORTAL_TARGETS = [{ name: 'Development',value:'dev'}, {name: 'Staging',value:'stage'}, {name: 'Production', value:'prod'}]

export const UP_TO_DATE = 'up to date';
export const BEHIND = 'behind';
export const LOCAL_CHANGES = 'local changes';
export const AHEAD = 'ahead';
export const DIVERGED = 'diverged';
export const NEEDS_RESTART = 'needs restart to apply';
export const INTERNET_CONNECTION_ERROR_MESSAGE = 'Connection error. Please check your network and try again later.';

export const TEMP_CHART_HEIGHT = 300;

export const USE_CASES = {
  SNOW_PLOWING: 'Snow Plowing',
  // NON_SOLAR_LAWN_MOWING: 'Non-Solar Lawn Mowing',
  SOLAR_LAWN_MOWING: 'Solar Vegetation'
};

export const HEARTBEAT_TIMEOUT = 10000;

export const ALL_USE_CASES = [USE_CASES.SNOW_PLOWING, USE_CASES.SOLAR_LAWN_MOWING];

export const ROLES = {
  admin: 'Admin',
  manager: 'Manager',
  chaperone: 'Chaperone',
  remoteGuardian: 'Remote Guardian',
  auditOfficer: 'Audit Officer',
  client: 'Client'
};

export const ROLES_LIST = [
  { id: 'admin', name: 'Admin' },
  { id: 'manager', name: 'Manager' },
  { id: 'chaperone', name: 'Chaperone' },
  { id: 'remoteGuardian', name: 'Remote Guardian' },
  { id: 'auditOfficer', name: 'Audit Officer' },
  { id: 'client', name: 'Client' }
];

export const DIRECTIONS = {
  EAST: 'E',
  WEST: 'W',
  NORTH: 'N',
  SOUTH: 'S',
  E: 'EAST',
  W: 'WEST',
  N: 'NORTH',
  S: 'SOUTH'
};

export const EAST = 'EAST';
export const WEST = 'WEST';

export const ZONES = {
  ONE: 'ONE',
  TWO: 'TWO'
};

export const INFRACTION_DIRECTIONS = {
  ZERO: 'ZERO',
  ONE: 'ONE',
  TWO: 'TWO',
  THREE: 'THREE',
  FRONT: 'FRONT',
  RIGHT: 'RIGHT',
  REAR: 'REAR',
  LEFT: 'LEFT'
};

export const INFRACTION_TYPES = {
  HUMAN: 'HUMAN',
  ANIMAL: 'ANIMAL',
  DRIVE_LINE: 'DRIVE_LINE',
  PANEL: 'PANEL'
};

export const GIT_STATUS = {
  0: UP_TO_DATE,
  1: BEHIND,
  2: LOCAL_CHANGES,
  3: AHEAD,
  4: DIVERGED,
  5: NEEDS_RESTART
};

export const ICON_TYPES = {
  PROBLEM: 'PROBLEM',
  WARNING: 'WARNING'
};

export const HIGH_LATENCY_THRESHOLD_SECONDS = 0.5;

export const MetricsCategory = {
  Distances: 'Distances',
  Acreages: 'Acreages',
  Speeds: 'Speeds',
  Robots: 'Robots'
};

export const DistanceCategory = {
  Distances: 'Distances',
  DistancesPerRobot: 'Km/Robot',
  Acreages: 'Acreages',
  AcreagesPerRobot: 'Acres/Robot'
};

export const TimeCategory = {
  Ops: 'Ops',
  OpsPerRobot: 'Ops/Robot',
  Mowing: 'Mowing',
  MowingPerRobot: 'Mowing/Robot'
};

export const DownTimeCategory = {
  Ops: 'Ops',
  Mowing: 'Mowing',
  MowingPerRobot: 'Mowing/Robot'
};

// If the distance between adjacent coordinates exceeds this value in meters,
// then the snail trail will be drawn as two separate lines.
export const COORDINATE_DISTANCE_APART_THRESHOLD = 100;

export const wpsStateEnum = [];
wpsStateEnum[0] = 'Idle State';
wpsStateEnum[1] = 'Waypoints Recording Runnning';
wpsStateEnum[2] = 'Waypoints Recording Paused';
wpsStateEnum[3] = 'Waypoints Recording Done';
wpsStateEnum[4] = 'Waypoints Execution Runnning';
wpsStateEnum[5] = 'Waypoints Execution Paused';
wpsStateEnum[6] = 'Waypoints Execution Done';
wpsStateEnum[50] = 'Waypoints Recording Suspended';
wpsStateEnum[120] = 'Streaming Timeout';

export const plowStateEnum = [];
plowStateEnum[0] = 'V-Mode';
plowStateEnum[1] = 'Scoop';
plowStateEnum[2] = 'Angled Left';
plowStateEnum[3] = 'Angled Right';
plowStateEnum[4] = 'Unknown';

export const plowHeightEnum = [];
plowHeightEnum[0] = 'Down';
plowHeightEnum[1] = 'Up';

export const PATH_TYPES = {
  SIGMOID: 'sigmoid',
  DRY_CENTER: 'dry_center',
  LEGACY: 'legacy'
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const ERROR_TIME_THRESHOLD = 5;

export const robotCurrentStatus = {
  OPERATIONAL: 'operational',
  IDLE: 'idle',
  BLADE_RUNNING: 'bladesRuning'
};

export const autonomyNavigationModes = [
  {
    id: 't-r',
    name: 'Teach & Repeat'
  },
  {
    id: 'infinite',
    name: 'Infinite Execution'
  },
  {
    id: 'routefollower',
    name: 'Route Following'
  }
];

export const attachmentTypes = [
  {
    id: 'plow',
    name: 'Plow'
  },
  {
    id: 'roughmower',
    name: 'Rough Mower'
  },
  {
    id: 'forklift',
    name: 'Forklift'
  },
  {
    id: 'treeplanter',
    name: 'Tree Planter'
  },
  {
    id: 'cargobox',
    name: 'Cargo Box'
  }
];

export const robotDeploymentTargetTypes = [
  {
    id: 'dev',
    name: 'Dev'
  },
  {
    id: 'stage',
    name: 'Stage'
  },
  {
    id: 'prod',
    name: 'Production'
  }
];
export const STATUS_MAP = {
  0: 'AVAILABLE',
  1: 'IN_USE',
  2: 'IN_USE',
  3: 'IN_USE',
  4: 'IN_USE',
  5: 'IN_USE',
  6: 'IN_USE',
  40: 'EXEC_SUSPENDED',
  50: 'WPS_SUSPENDED',
  99: 'UNINTIALIZED',
  100: 'OFFLINE'
};

/** Number values for deck offsets */
export const DECK_OFFSET = {
  NEUTRAL: 0,
  RIGHT: 1,
  LEFT: -1
};

export const DECK_OFFSET_POSITION = {
  RIGHT_OFFSET: 'right_roughmower',
  LEFT_OFFSET: 'roughmower',
  FLOAT: 'float_roughmower'
};

/** Deck offset visualization slider marks */
export const DECK_AND_FRAME_OFFSET_SLIDER_MARKS = {
  deckOffset: [
    {
      value: -76,
      label: 'L'
    },
    {
      value: 0,
      label: 'CENTER'
    },
    {
      value: 76,
      label: 'R'
    }
  ],
  frameOffset: [
    {
      value: -65,
      label: 'L'
    },
    {
      value: 0,
      label: 'CENTER'
    },
    {
      value: 65,
      label: 'R'
    }
  ]
};

// Timeout for triggering a disconnect. Used for heatbeat mechanisim
export const CONNECTION_TIMEOUT_THRESHOLD = 3000;

export const INACTIVE_PROMPT_TIMEOUT = 11; // 11 seconds
// Used in multi-robots page for Active RG feature
export const MIN_INTERVAL = 2 * 60 * 1000; // 2 minutes in milliseconds
export const MAX_INTERVAL = 4 * 60 * 1000; // 4 minutes in milliseconds

// PS4 Teleops Parameters
export const PS4_PARAMETERS = {
  analogDeadZone: 0.1,
  maxSpeed: 2.429512,
  maxRotationRate: 2.25,
  maxSpeedLimit: 3,
  gamepadIndex: 0
};

/** Autonomous blades toggle values */
export const TOGGLE_AUTONOMOUS_BLADES = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0];

/** Robot control modes */
export const ROBOT_CONTROL_MODES = {
  MANUAL: 'manual',
  AUTONOMOUS: 'autonomous',
  TELEOPS: 'teleops'
};

/** Batteries warning levels */
export const BATTERIES_WARNING_LEVELS = {
  LOW_LEVEL: 10,
  CRITICAL_LEVEL: 1
};

/** Minimum lower threshold velocity for dynamic video FOV */
export const IN_REVERSE_VELOCITY_THRESHOLD = -0.15;

/** Robot status custom sorting order (asc) */
export const robotStatusCustomOrder = {
  SENSING_EDGES_BYPASSED: 0,
  CONTROL_LOCKOUT: 0,
  AVAILABLE: 1,
  IN_USE: 2,
  TELEOPS: 3,
  EXEC_SUSPENDED: 4,
  PROVISIONING: 5,
  UNINTIALIZED: 6,
  OFFLINE: 7
};

export const INTER_ROW_MODES = [
  { name: 'Floss', value: 'floss' },
  { name: 'Tangent', value: 'tangent' }
];

export const GENERATE_METHODS = [
  { name: 'Coverage', value: 'coverage' },
  { name: 'Lane Based', value: 'lane_based' }
];

export const GENERATE_METHODS_OPTIONS = {
  coverage: [
    { name: 'Left', value: 'left' },
    { name: 'Right', value: 'right' },
    { name: 'Left Under Panel', value: 'left_under_panel' },
    { name: 'Right Under Panel', value: 'right_under_panel' }
  ],
  lane_based: [
    { name: 'Center Left', value: 'center_left' },
    { name: 'Center Right', value: 'center_right' },
    { name: 'Left Under Panel', value: 'left_under_panel' },
    { name: 'Right Under Panel', value: 'right_under_panel' }
  ]
};

export const WEATHER_CONDITION = [
  { name: 'Dry', value: 'dry' },
  { name: 'Wet', value: 'wet' },
  { name: 'Moderate', value: 'moderate' }
];

export const VEGETATION_CONDITION = [
  { name: 'Moderate', value: 'moderate' },
  { name: 'Dense', value: 'dense' }
];
/** Parameter keys in Edit robot used to initialize UI state variables */
export const robotParameterKeys = [
  'autonomyNavigationMode',
  'attachmentType',
  'autonomyAttachmentController',
  'autonomyNavigationDeviationCheck',
  'deviationPositionSlowThreshold',
  'deviationPositionStopThreshold',
  'deviationHeadingSlowThreshold',
  'deviationHeadingStopThreshold',
  'deviationPositionSlowSpeed',
  'enableDevBot',
  'enableManualBladesDuringAutonomy',
  'enableAutonomousBladesDuringAutonomy',
  'enableAutonomousAttachmentControl',
  'acceptableZedLevel',
  'enableInterRowHopping',
  'isLowStreamingEnabled',
  'isTeleopsStreamingEnabled',
  'isRightOffsetDeck',
  'autonomyNavigationController',
  'attachmentOffsetDirection',
  'interrowType',
  'overworkedMotorsCheck',
  'dynamicMowingSpeed',
  'stuckAutonomyCheck',
  'stuckSpeedThreshold',
  'stuckExecutionProgress',
  'stuckCurrentThreshold',
  'stuckTimeout',
  'enableRosbagRecording',
  'enableRosbagUploading',
  'enableBaseSensor',
  'enableTankSensor',
  'enableAdasChaperone',
  'coolingHoseEnabled',
  'deckPotWorks',
  'deckPotHomeValue',
  'framePotWorks',
  'framePotHomeValue'
];

// Default path type for All Sub-blocks Solar map
export const DEFAULT_PATH_TYPE = 'wet';

export const SUBROW_STATUS_MAP = {
  false: 'NOT_STARTED',
  true: 'COMPLETED'
};

export const SUBROW_RELATION_COLUMNS_MAP = {
  IS_NO_GO: 'is_restricted',
  STATUS: 'status',
  KEEP_OUT: 'is_keep_out_zone',
  CLEANED_UP: 'is_cleaned_up'
};

export const SUBROWS_TABLE_COLUMN_HEADERS = {
  IS_NO_GO: 'No-Go',
  ROBOTIC_CUT: 'Robotic Cut',
  KEEP_OUT: 'Keep Out',
  CLEANED_UP: 'Cleaned Up'
};

export const ROBOTS_MAP_ALERT_MESSAGE = {
  RESET_MAP: { TITLE: 'Reset Map', BODY: 'Map Needs To Be Reset Before Plotting Subrows' },
  BUSY_SERVER: { TITLE: 'Service Unavaliable', BODY: 'Service is currently unavliable. Please try again later' }
};

export const GPS_STATUS_MAP = {
  FIXED_RTK: 'Fixed RTK',
  FLOAT_RTK: 'Float RTK',
  SBAS: 'SBAS'
};

export const BATTERY_FAULT_STATUS = {
  FAULTED: 'Faulted',
  HEALTH: 'Healthy'
};

export const TOOL_OFFSET_DIRECTION = [
  { name: 'Left', value: 'left' },
  { name: 'Right', value: 'right' }
];

export const INTER_ROW_TYPE = [
  { name: 'Config', value: 'config' },
  { name: 'Stock', value: 'stock' },
  { name: 'Sigmoid', value: 'sigmoid' },
  { name: 'Dynamic', value: 'dynamic' }
];

export const EDIT_ROBOTS_CONFIG_TITLES = [
  { name: 'Autonomy Settings', value: 'autonomyConfig' },
  { name: 'Site Settings', value: 'siteConfig' },
  { name: 'Robot System Settings', value: 'systemConfig' },
  { name: 'Hardware Settings', value: 'hardwareConfig' }
];

/** Map of ROS params in their respective groups */
export const ROS_PARAM_GROUP_MAP = {
  autonomy: {
    general: [
      'enableManualBladesDuringAutonomy',
      'enableAutonomousBladesDuringAutonomy',
      'enableAutonomousAttachmentControl',
      'enableInterRowHopping',
      'autonomyNavigationController',
      'interrowType',
      'dynamicMowingSpeed'
    ],
    deviation: [
      'autonomyNavigationDeviationCheck',
      'deviationPositionSlowThreshold',
      'deviationPositionStopThreshold',
      'deviationHeadingSlowThreshold',
      'deviationHeadingStopThreshold',
      'deviationPositionSlowSpeed'
    ],
    stuck: ['stuckAutonomyCheck', 'stuckSpeedThreshold', 'stuckExecutionProgress', 'stuckCurrentThreshold', 'stuckTimeout'],
    attachment: ['attachmentType', 'autonomyAttachmentController', 'isRightOffsetDeck', 'attachmentOffsetDirection']
  },
  system: {
    camera: ['acceptableZedLevel', 'isLowStreamingEnabled', 'isTeleopsStreamingEnabled'],
    sensor: ['overworkedMotorsCheck', 'enableRosbagRecording', 'enableRosbagUploading', 'enableBaseSensor', 'enableTankSensor'],
    misc: ['enableDevBot', 'enableAdasChaperone']
  },
  hardware: {
    deck: ['deckPotWorks', 'deckPotHomeValue'],
    frame: ['framePotWorks', 'framePotHomeValue'],
    cooling: ['coolingHoseEnabled']
  }
};

// used for manage/configure robots info tooltip
export const CONFIGURE_ROBOTS_INFO_DIALOGUE = [
  {
    title: 'Autonomy Configuration',
    body: 'Modify and fine tune robot parameters related to autonomy such as deviation check, attachement settings, stalled autonomy, and more. Autonomy modifications will affect Swapbot code and might require a restart'
  },
  {
    title: 'Site Configurations',
    body: "Modify robot's location, name, and more on the web portal. Site configuration do not affect Swapbot code"
  },
  {
    title: 'System Configuration',
    body: 'Modify robot system settings such as ROS bag loggin and camera settings. System modifications will affect Swapbot code and might require a restart'
  },
  {
    title: 'Hardware Configuration',
    body: 'Modify hardware settings such as Hose cooling, robot deck and frame health status, and more. Hardware modifications will affect Swapbot code and wiil require a restart'
  }
];

// used to show different types of estop signs on repeat pages
export const ESTOP_TYPES = {
  SENSING_EDGES: 'sensingEdge',
  SW_ESTOP: 'swEstop',
  CONTROL_LOCKOUT: 'controlLockout',
  PHYSICAL_ESTOP: 'estop',
  SAFETY_STOP: 'safetyStop'
};

// used for reseting solar subrow status in manage locations
export const SUBROW_RESET_TYPE = {
  CUT_STATUS: 'status',
  CLEANED_UP: 'is_cleaned_up'
};

// used for service calls to reset BT adapter and pair controller on diagnositcs
export const BLUETOOTH_SERVICES = {
  RESET_BLUETOOTH_ADAPTER: {
    dialogueMessage: 'Resetting Bluetooth Adapter',
    successMessage: 'Adapter Reset Successfully'
  },
  PAIR_CONTROLLER: { dialogueMessage: 'Pairing Controller', successMessage: 'Pairing Successful' }
};

export const INTER_ROW_DIRECTIONS = {
  'l-r': 'Forward',
  'r-l': 'Reverse'
};

// default values for tele-ops satatus topic
export const TELEOPS_STATUS_DEFAULT = {
  staleTeleopsJoy: false,
  joyLatency: -1
};

// map for currently tracked latency names
export const LATENCY_NAMES = {
  WEBSOCKET: 'webSocketLatency',
  MQTT: 'mqttLatency',
  TELEOPS_JOY: 'joyLatency',
  SERVER: 'serverLatency'
};
