import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import { useStores } from '../../store/root/root.store';
import { celsiusToFahrenheit } from '../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    color: theme.palette.primary.contrastText
  },
  rowItem: {
    backgroundColor: theme.palette.grey[500],
    color: 'Black',
    marginBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    width: '100%',
    textAlign: 'center',
    fontSize: '0.9em'
  }
}));

const SingleMotorStatus = ({ motors, id }) => {
  const { controlStore } = useStores();

  return useObserver(() => {
    const motor = motors ? motors.get(id) ?? { motor_temp: '?' } : controlStore.motors.get(id) ?? { motor_temp: '?' };
    const temp = motor.motor_temp;

    // Define the style for temperature highlighting
    const tempStyle = {
      color: temp > 105 ? 'red' : temp > 90 ? 'yellow' : 'inherit', // Red color if temp > 90°C
      fontWeight: 'bold' // Bold text if temp > 90°C
    };

    return (
      <Grid
        item
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        xs={12}
        key={id}
        style={{ border: '2px' }}
      >
        <Grid
          container
          item
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          xs={12}
          style={{ paddingLeft: '8px', border: '2px' }}
        >
          <Typography>
            {`${id}: `}
            <span style={tempStyle}>
              {`${temp}°C`}
              {` | ${celsiusToFahrenheit(temp)}°F`}
            </span>
          </Typography>
        </Grid>
      </Grid>
    );
  });
};

export const MotorStatus = ({ motors }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="center" className={classes.root}>
      <Grid item className={classes.rowItem}>
        <Typography>Motors Info </Typography>
      </Grid>
      <Grid item container direction="row" justifyContent="flex-start" alignItems="stretch" className={classes.rowItem}>
        <SingleMotorStatus motors={motors} id="LEFT" />
        <SingleMotorStatus motors={motors} id="RIGHT" />
        <SingleMotorStatus motors={motors} id="HYDRAULIC" />
      </Grid>
    </Grid>
  );
};
