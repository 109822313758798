/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { Motor } from './motor.model';
import { ChaperoneRobotConnectionService } from '../../services/chaperone/robot-connection.service';

export const ImageData = types.model('ImageData', {
  name: types.string,
  data: types.string
});
export const ControlStore = types
  .model('ControlStore', {
    lng: types.optional(types.number, 0),
    lat: types.optional(types.number, 0),
    isSalting: false,
    isLawnMowing: false,
    motors: types.map(Motor, {}),
    images: types.map(types.string),
    imageRequested: false,
    speedLimit: 0,
    robotStateStamp: 0,
    wpsState: 0, // 0-> Idle, 1 -> recording_runing, 2 -> record_paused, 3 -> record_done and same for execution 4,5,6
    previousWpsState: 0,
    controlMode: 'manual',
    plowControlMode: 'manual',
    salterControlMode: 'manual',
    previousControlMode: 'manual',
    current_heading_rad: 0.0,
    mower_speed: 0.0,
    plowState: 0,
    lightsState: false,
    beeperState: false,
    fanState: false,
    wiperState: false,
    estopState: false,
    swEstopState: false,
    salter_level: 0,
    batteryLevel: types.maybeNull(types.number),
    numberofBatteries: 0,
    plowHeight: 0,
    plowMode: '',
    isFirstLocation: true,
    isFirstHeading: true,
    isRepeatingDone: false,
    portalClientCount: 0,
    softwareBranch: '',
    robotDeploymentTarget: '',
    autonomyNavigationMode: '',
    autonomyNavigationController: '',
    attachmentType: '',
    gpsFixStatus: 'RTK Fixed',
    stableLocalization: false,
    firstLng: types.optional(types.number, 0),
    firstLat: types.optional(types.number, 0),
    firstHeading: 0.0,
    error: types.optional(types.string, ''),
    notification: 'No New Notifications',
    highLatency: false,
    latency: 0,
    safetyEnabled: true,
    safetyErrorMessage: '',
    activeClientUUID: '',
    autonomyDeviationDistance: types.optional(types.number, -1),
    deckOffset: types.optional(types.number, 0.0),
    frameOffset: types.optional(types.number, 0),
    isRightOffsetDeck: types.maybeNull(types.boolean),
    isTeleopsStreamingEnabled: types.optional(types.boolean, false),
    isLowStreamingEnabled: types.optional(types.boolean, false),
    deviationPositionSlowSpeed: types.optional(types.number, 0),
    deviationPositionStopThreshold: types.optional(types.number, 0),
    deviationPositionSlowThreshold: types.optional(types.number, 0),
    deviationHeadingStopThreshold: types.optional(types.number, 0.0),
    deviationHeadingSlowThreshold: types.optional(types.number, 0.0),
    autonomyNavigationDeviationCheck: types.optional(types.number, 0),
    enableInterRowHopping: types.optional(types.boolean, false),
    enableDevBot: types.optional(types.boolean, false),
    acceptableZedLevel: types.optional(types.number, 0),
    enableAutonomousAttachmentControl: types.optional(types.number, 0),
    enableAutonomousBladesDuringAutonomy: types.optional(types.boolean, false),
    enableManualBladesDuringAutonomy: types.optional(types.boolean, false),
    autonomyAttachmentController: types.optional(types.string, ''),
    dynamicParamsUpdated: false,
    staticParamsUpdated: false,
    currentRow: types.maybeNull(types.number),
    currentRowName: types.maybeNull(types.string),
    auotnomyStoppedDeviated: types.optional(types.boolean, false),
    attachmentOffsetDirection: types.maybeNull(types.string),
    interrowType: types.maybeNull(types.string),
    overworkedMotorsCheck: types.maybeNull(types.boolean),
    dynamicMowingSpeed: types.maybeNull(types.boolean),
    stuckAutonomyCheck: types.maybeNull(types.boolean),
    stuckSpeedThreshold: types.maybeNull(types.number),
    stuckExecutionProgress: types.maybeNull(types.number),
    stuckCurrentThreshold: types.maybeNull(types.number),
    stuckTimeout: types.maybeNull(types.number),
    enableRosbagRecording: types.maybeNull(types.boolean),
    enableRosbagUploading: types.maybeNull(types.boolean),
    enableBaseSensor: types.maybeNull(types.boolean),
    enableTankSensor: types.maybeNull(types.boolean),
    enableAdasChaperone: types.maybeNull(types.boolean),
    coolingHoseEnabled: types.maybeNull(types.boolean),
    deckPotWorks: types.maybeNull(types.boolean),
    deckPotHomeValue: types.maybeNull(types.number),
    framePotWorks: types.maybeNull(types.boolean),
    framePotHomeValue: types.maybeNull(types.number)
  })
  .views((self) => ({
    getLocation() {
      return { lat: self.lat, lng: self.lng };
    },
    getCameraImage(label) {
      return self.images.get(label);
    },

    isInTeachingSession() {
      return self.wpsState === 1 || self.wpsState === 2 || self.wpsState === 3;
    },
    getNotificationMessage() {
      return self.notification === '' ? 'No Notifications Yet' : self.notification;
    },
    getAutonomyDeviationDistance() {
      return self.autonomyDeviationDistance;
    }
  }))
  .actions((self) => ({
    updateRobotState(robotStateStamped) {
      self.robotStateStamp = robotStateStamped.stamp;
      self.lastRobotStateReceived = Date.now();
      const robotControlState = robotStateStamped.robot_state.control_state;
      const robotLocationState = robotStateStamped.robot_state.location_state;

      self.setLocation(robotLocationState);
      self.setMotorsState(robotControlState.motors);
      self.setSalterLevel(robotControlState.snow_state.salter_percentage);

      self.isLawnMowing = robotControlState.solar_state.blades_running;
      self.previousWpsState = self.wpsState;
      self.wpsState = robotStateStamped.robot_state.navigation_state.wps_state;
      self.attachementType = robotStateStamped.robot_state.control_state.tool_type;
      self.portalClientCount = robotStateStamped.robot_state.network_state.portal_client_count;
      self.robotDeploymentTarget = robotStateStamped.robot_state.deployment_state?.deployment_target;
      self.softwareBranch = robotStateStamped.robot_state.deployment_state?.branch_deployed;
      self.previousControlMode = self.controlMode;
      self.controlMode = robotControlState.control_mode;
      self.plowHeight = robotControlState.snow_state.plow_height;
      self.plowState = robotControlState.snow_state.plow_state;
      self.plowControlMode = robotControlState.snow_state.plow_control_mode;
      self.salterControlMode = robotControlState.snow_state.salter_control_mode;
      self.lightsState = robotControlState.light_is_on;
      self.beeperState = robotControlState.beeper_is_on;
      self.fanState = robotControlState.fan_is_on;
      self.wiperState = robotControlState.wiper_is_on;
      self.estopState = robotStateStamped.robot_state.safety_state.estop_is_on;
      self.swEstopState = robotStateStamped.robot_state.safety_state.sw_estop_is_on;
      self.batteryLevel = robotControlState.battery_state.percentage;
      self.numberofBatteries = robotControlState.battery_state.number_of;
      self.safetyEnabled = robotStateStamped.robot_state.safety_state.safety_enabled;
      self.safetyErrorMessage = robotStateStamped.robot_state.safety_state.safety_error_message;
      self.deckOffset = parseFloat(robotStateStamped?.robot_state?.control_state?.solar_state.deck_offset);
      self.frameOffset = parseFloat(robotStateStamped?.robot_state?.control_state?.solar_state.frame_offset);
      self.currentRow = parseInt(robotStateStamped?.robot_state?.navigation_state?.current_path_state?.path_db_id);
      self.currentRowName = robotStateStamped?.robot_state?.navigation_state?.current_path_state?.path_name;
      self.auotnomyStoppedDeviated =
        robotStateStamped?.robot_state?.navigation_state?.current_path_state?.position_deviation >= self.deviationPositionStopThreshold ||
        robotStateStamped?.robot_state?.navigation_state?.current_path_state?.heading_deviation_rad >= self.deviationHeadingStopThreshold;
    },
    updateStaticParameters(parametersObject) {
      Object.entries(parametersObject).forEach(([name, value]) => {
        if (name in self) {
          self[name] = value;
        }
      });
      self.autonomyNavigationMode !== '' ? (self.staticParamsUpdated = true) : (self.staticParamsUpdated = false);
    },
    updateDynamicParameters(parametersObject) {
      Object.entries(parametersObject).forEach(([name, value]) => {
        if (name in self) {
          self[name] = value;
        }
      });
      self.autonomyNavigationMode !== '' ? (self.dynamicParamsUpdated = true) : (self.dynamicParamsUpdated = false);
    },
    setLocation(locationState) {
      if (self.isFirstLocation) {
        self.isFirstLocation = false;
        self.firstLat = locationState.latitude;
        self.firstLng = locationState.longitude;
      }
      if (self.isFirstHeading) {
        self.isFirstHeading = false;
        self.firstHeading = locationState.heading_radian;
      }
      self.current_heading_rad = locationState.heading_radian;
      self.lat = locationState.latitude;
      self.lng = locationState.longitude;
      self.gpsFixStatus = locationState.gps_fix_status;
      if (locationState.is_localization_stable) {
        self.stableLocalization = true;
      } else {
        self.stableLocalization = false;
      }
    },
    setSalting(isSalting) {
      self.isSalting = isSalting;
    },
    setDoneRepeating(msg) {
      self.isRepeatingDone = msg.data;
    },
    resetDoneRepeating() {
      self.isRepeatingDone = false;
    },
    setSalterLevel(level) {
      self.salter_level = Number(level);
      if (self.salter_level >= 1) {
        self.setSalting(true);
      } else {
        self.setSalting(false);
      }
    },
    setMotorsState(motors) {
      motors.forEach((motor) => {
        self.motors.set(motor.id, motor);
      });
    },
    updateLatency(highLatency, latency) {
      self.highLatency = highLatency;
      self.latency = latency;
    },
    setCameraFrame(label, message) {
      self.images.set(label, message.data);
    },
    setImageRequested(requested) {
      self.imageRequested = requested;
    },
    setSpeedLimit(limit) {
      self.speedLimit = limit;
    },
    setLightsLock(lightsLock) {
      self.lights_lock = lightsLock;
    },
    setBeeperLock(beeperLock) {
      self.beeper_lock = beeperLock;
    },
    getNumberOfBatteries() {
      return self.numberofBatteries;
    },
    getBatteryPercentage() {
      return self.batteryLevel;
    },
    resetRobotPosition() {
      self.current_heading_rad = 0.0;
      self.lat = 0;
      self.lng = 0;
      self.controlMode = 'manual';
      self.previousControlMode = 'manual';
      self.error = '';
      self.resetDoneRepeating();
    },
    setPreviousWpsState(state) {
      // console.debug('Current Wps Status Msg: ', status)
      self.previousWpsState = state;
    },
    getControlMode() {
      return self.controlMode;
    },
    getPlowControlMode() {
      return self.plowControlMode;
    },
    getSalterControlMode() {
      return self.salterControlMode;
    },
    setError(error) {
      self.error = error.data;
    },
    setNotificationMessage(message) {
      self.notification = message;
    },
    setAutonomyDeviationDistance(message) {
      self.autonomyDeviationDistance = message.data;
    },
    resetStore() {
      // TODO run this when there's no robot_state data coming for more than 5 seconds
      self.wpsState = 0;
      self.softwareBranch = '';
      self.autonomyNavigationMode = '';
      self.autonomyNavigationController = '';
      self.attachementType = '';
      self.portalClientCount = 0;
      self.safetyEnabled = true;
      self.resetDoneRepeating();
      self.notification = '';
    },
    getCurrentActiveClient(robot, onSuccess = () => {}, onFailure = () => {}) {
      let robotConnectionService;
      (async () => {
        try {
          // On first load, initiate the robot connection
          console.debug('connecting to robot for getCurrentActiveClient ', robot.name);
          robotConnectionService = new ChaperoneRobotConnectionService(
            () => {
              // onConnected
              // To trigger the janus ML if it wasn't active
              const robotConnection = robotConnectionService;
              robotConnection?.ros?.subscribeToRobotStateStamped((robotState) => {
                if (robotConnectionService !== null) {
                  robotConnectionService?.destroy();
                }
                onSuccess(robotState.robot_state.network_state.active_client);
              });
            },
            () => {
              // onDisconnect
              console.debug('Lost getCurrentActiveClient connection to robot', robot.name);
              // handleConnectionError();
            },
            robot.serial_number,
            '',
            ''
          );
        } catch (error) {
          console.error('Failed to connect for getCurrentActiveClient', error);
        }
      })();

      robotConnectionService?.connectToRobot(() => {});
    },
    removeCurrentActiveClient(robot, onSuccess = () => {}, onFailure = () => {}) {
      let robotConnectionService;
      (async () => {
        try {
          // On first load, initiate the robot connection
          console.debug('connecting to robot for removeCurrentActiveClient', robot.name);
          robotConnectionService = new ChaperoneRobotConnectionService(
            () => {
              // onConnected
              // To trigger the janus ML if it wasn't active
              const robotConnection = robotConnectionService;
              robotConnection?.ros?.cmdRemoveCurrentActiveClient((result) => {
                if (robotConnectionService !== null) {
                  robotConnectionService?.destroy();
                }
                if (result.success) {
                  onSuccess();
                } else {
                  onFailure();
                }
              });
            },
            () => {
              // onDisconnect
              console.debug('Lost removeCurrentActiveClient connection to robot', robot.name);
              // handleConnectionError();
            },
            robot.serial_number,
            '',
            ''
          );
        } catch (error) {
          console.error('Failed to connect for removeCurrentActiveClient', error);
        }
      })();

      robotConnectionService?.connectToRobot(() => {});
    }
  }));
