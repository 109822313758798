import React, { useState } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { makeStyles, Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(() => ({
  tableContainer: {
    margin: 2,
    border: '2px solid grey',
    borderRadius: '4px',
    width: '100%',
    height: 'fit-content',
    boxSizing: 'border-box'
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative'
  },
  table: {
    fontSize: '17px',
    '& .p-treetable-thead > tr > th': {
      backgroundColor: '#f5f5f5',
      color: '#333',
      padding: '8px',
      borderBottom: '1px solid #ccc'
    },
    '& .p-treetable-tbody > tr > td': {
      padding: '8px',
      borderBottom: '1px solid #eee'
    },
    '& .p-treetable-tbody > tr.p-highlight > td': {
      backgroundColor: '#fa6257'
    }
  },
  errorCell: {
    maxWidth: 'max-content',
    display: 'flex',
    alignItems: 'center'
  },
  stickyColumn: {
    position: 'sticky',
    left: 0,
    backgroundColor: '#f5f5f5',
    zIndex: 1,
    borderRight: '1px solid #ccc'
  }
}));

/**
 * UsbDevicesTable - A table component that displays data in a tree like structure
 *
 * This componet displays device diganotics data in a tree like structure. Rows can have child
 * rows, which are nested when expanded
 * @param {Array} data
 * @returns {Component} functional component
 */
const UsbDevicesTable = observer(({ data }) => {
  const classes = useStyles();
  const [expandedKeys, setExpandedKeys] = useState({});

  // sample data
  const rows = [
    {
      key: '0',
      label: 'ORIN USB PORT 1',
      data: {
        device: 'ORIN USB PORT 1',
        port: 1,
        connectedToOrinVia: 'Direct',
        targetHub: 'Direct',
        targetPort: 'NA'
      },

      children: []
    },
    {
      key: '1',
      label: 'ORIN USB PORT 2',
      data: {
        device: 'ORIN USB PORT 2',
        port: 2,
        connectedToOrinVia: 'Direct',
        targetHub: 'Direct',
        targetPort: 'NA'
      },

      children: []
    },
    {
      key: '2',
      label: 'ORIN USB PORT 3',
      data: {
        device: 'ORIN USB PORT 3',
        port: 3,
        connectedToOrinVia: 'Direct',
        targetHub: 'Direct',
        targetPort: 'NA'
      },

      children: []
    },
    {
      key: '3',
      label: 'ORIN',
      data: {
        device: 'ORIN USB PORT 4',
        port: 4,
        connectedToOrinVia: 'Direct',
        targetHub: 'Direct',
        targetPort: 'NA'
      },

      children: [
        {
          key: '3-0',
          label: 'Top Front',
          data: {
            device: 'Top Front',
            port: 4,
            connectedToOrinVia: 'Direct',
            targetHub: 'Direct',
            targetPort: 4
          },

          children: [
            {
              key: '3-0-3',
              label: 'Rear',
              data: {
                device: 'Rear Hub',
                connectedToOrinVia: 'Top Front Hub',
                port: 1,
                targetHub: 'Top Front Hub',
                targetPort: 2
              },
              children: [
                {
                  key: '3-0-3-0',
                  label: 'Bluetooth',
                  data: {
                    device: 'Bluetooth',
                    connectedToOrinVia: 'Rear Hub',
                    port: 1,
                    targetPort: 1,
                    targetHub: 'Rear Hub'
                  },

                  children: []
                },
                {
                  key: '3-0-3-1',
                  label: 'External Hub',
                  data: {
                    device: 'External Hub',
                    connectedToOrinVia: 'Rear Hub',
                    port: 2,
                    targetPort: 2,
                    targetHub: 'Rear Hub'
                  },

                  children: [
                    {
                      key: '3-0-3-1-0',
                      label: 'Rear Mast',
                      data: {
                        device: 'Rear Mast',
                        connectedToOrinVia: 'External Hub',
                        port: 1,
                        targetPort: 2,
                        targetHub: 'External Hub'
                      },

                      children: []
                    },
                    {
                      key: '3-0-3-1-1',
                      label: 'Front Mast',
                      data: {
                        device: 'Front Mast',
                        connectedToOrinVia: 'External Hub',
                        port: 3,
                        targetPort: 3,
                        targetHub: 'External Hub'
                      },

                      children: []
                    }
                  ]
                },
                {
                  key: '3-0-3-3',
                  label: 'Rear Teleops',
                  data: {
                    device: 'Rear Teleops',
                    connectedToOrinVia: 'Rear Hub',
                    port: 4,
                    targetPort: 4,
                    targetHub: 'Rear Hub'
                  },

                  children: []
                }
              ]
            },
            {
              key: '3-0-1',
              label: 'Aux Cable',
              data: {
                device: 'Aux Cable',
                connectedToOrinVia: 'Top Front Hub',
                port: 3,
                targetHub: 'Top Front Hub',
                targetPort: 3
              },

              children: []
            },
            {
              key: '3-0-0',
              label: 'Rear ZED',
              data: {
                device: 'Rear ZED',
                connectedToOrinVia: 'Top Front Hub',
                port: 4,
                targetHub: 'Top Front Hub',
                targetPort: 4
              },

              children: []
            }
          ]
        }
      ]
    }
  ];

  const handleToggle = (node) => {
    const { key } = node;
    setExpandedKeys((prev) => {
      const newKeys = { ...prev };
      if (newKeys[key]) {
        delete newKeys[key];
      } else {
        newKeys[key] = true;
      }
      return newKeys;
    });
  };

  // eslint-disable-next-line react/display-name
  const CellBody = (field) => (node) => {
    let className = classes.defaultCell;
    if (field === 'port' && node?.data?.connectedToOrinVia !== 'Direct' && node?.data.port !== node?.data.targetPort) {
      className = classes.errorCell;
      return (
        <span className={className}>
          {node?.data[field]}
          <ErrorIcon color="error" style={{ marginBottom: '2px' }} />
        </span>
      );
    }
    if (field === 'connectedToOrinVia' && node?.data.connectedToOrinVia !== node?.data.targetHub) {
      className = classes.errorCell;
      return (
        <span className={className}>
          {node?.data[field]}
          <ErrorIcon color="error" />
        </span>
      );
    }
    return <span className={className}>{node?.data[field]}</span>;
  };

  return (
    <Box className={classes.tableContainer}>
      <Box className={classes.tableWrapper}>
        <TreeTable
          className={classes.table}
          tableStyle={{ width: '100%', minWidth: '800px', height: '70%' }}
          value={rows}
          onNodeToggle={handleToggle}
          expandedKeys={expandedKeys}
        >
          <Column
            field="data.device"
            header="Device"
            expander
            body={CellBody('device')}
            style={{ minWidth: '150px' }}
            className={classes.stickyColumn}
            frozen
          />
          <Column
            field="data.connectedToOrinVia"
            header="Connection to Orin"
            body={CellBody('connectedToOrinVia')}
            style={{ minWidth: '150px' }}
          />
          <Column field="data.targetHub" header="Target Hub" body={CellBody('targetHub')} style={{ minWidth: '150px' }} />
          <Column field="data.port" header="Port" body={CellBody('port')} style={{ minWidth: '150px' }} />
          <Column field="data.targetPort" header="Target Port" body={CellBody('targetPort')} style={{ minWidth: '150px' }} />
        </TreeTable>
      </Box>
    </Box>
  );
});

export default UsbDevicesTable;
