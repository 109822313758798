import React from 'react';
import { FormControl, MenuItem, Grid, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react-lite';
import { USE_CASES } from '../../../utils/constants';
import { useStores } from '../../../store/root/root.store';

/**
 * This component is responsible for managing robots portal settings on Manage Robots
 * for Robot Configurations section
 * @param {Object} classes Makestyle instance for styling components
 * @param {Object} robot Robot object
 * @param {Boolean} formError Checks if form has error. Errors could be invalid values
 * @param {Function} requiredText Text message to indicate field is required
 * @param {String} useCase Current robot use case
 * @param {Function} setUseCase State setter for useCase
 * @param {String} country Current robot country
 * @param {Function} setCurrRegion state setter for robot region
 * @param {Function} setCurrProperty state setter for robot property
 * @param {Function} setCurrProperties state setter for properties belonging to current region
 * @param {Function} setCountry state setter for robot country
 * @param {String} robotName Robot name
 * @param {Function} setRobotName state setter for robot name
 * @param {Boolean} duplicates Array containing duplicate robot entries if any
 * @param {Function} setDuplicates state setter for duplcates
 * @param {String} currRegion Current robot region
 * @param {String} currProperty Current robot property
 * @param {String} currProperties Properties belonging to current region
 * @returns {Component} React Component
 */
const PortalConfigurations = observer(
  ({
    classes,
    robot,
    formError,
    requiredText,
    useCase,
    setUseCase,
    country,
    setCurrRegion,
    setCurrProperty,
    setCurrProperties,
    setCountry,
    robotName,
    setRobotName,
    duplicates,
    setDuplicates,
    currRegion,
    currProperty,
    currProperties
  }) => {
    const { countriesStore, regionsStore } = useStores();
    return (
      <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
        {/* robot home region */}
        <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              required
              id="home-region"
              label="Home region"
              variant="outlined"
              InputProps={{ readOnly: true }}
              disabled
              value={robot.home_region_name}
            />
          </FormControl>
        </Grid>
        {/* robopt home property */}
        <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              required
              id="home-property"
              label="Home property"
              variant="outlined"
              InputProps={{ readOnly: true }}
              disabled
              value={robot.home_property_name}
            />
          </FormControl>
        </Grid>
        {/* use case */}
        <Tooltip title="This changes whether the robot appears on the Solar Vegetation or Snow Plowing pages">
          <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                required
                id="use-case"
                select
                label="Use case"
                variant="outlined"
                value={useCase}
                onChange={(event) => {
                  setUseCase(event.target.value);
                }}
                error={!useCase && formError}
              >
                {Object.entries(USE_CASES).map(([key, name]) => (
                  <MenuItem key={key} value={key}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
              {!useCase && formError && requiredText('Required')}
            </FormControl>
          </Grid>
        </Tooltip>
        {/* robot current country */}
        <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              required
              id="country"
              select
              label="Country"
              variant="outlined"
              value={country}
              onChange={(event) => {
                setCurrRegion('');
                setCurrProperty('');
                setCurrProperties([]);
                setCountry(event.target.value);
              }}
              error={!country && formError}
            >
              {countriesStore.countries.map((countryObject) => (
                <MenuItem key={countryObject.id} value={countryObject.id}>
                  {countryObject.name}
                </MenuItem>
              ))}
            </TextField>
            {!country && formError && requiredText('Required!')}
          </FormControl>
        </Grid>
        {/* robot serial number */}
        <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              required
              id="serial-number"
              label="Serial number"
              variant="outlined"
              InputProps={{ readOnly: true }}
              disabled
              value={robot.serial_number}
            />
          </FormControl>
        </Grid>
        {/* robot name */}
        <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              required
              id="robot-name"
              label="Robot name"
              variant="outlined"
              value={robotName}
              onChange={(event) => {
                setRobotName(event.target.value);
                setDuplicates(duplicates.filter((dup) => dup !== 'robotName'));
              }}
              error={(!robotName && formError) || duplicates.includes('robotName')}
            />
            {!robotName && formError && requiredText('Required!')}
            {duplicates.includes('robotName') && requiredText('This robot name already exists!')}
          </FormControl>
        </Grid>
        {/* robot current region */}
        <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              required
              id="current-region"
              select
              label="Current region"
              variant="outlined"
              value={currRegion}
              onChange={(event) => {
                setCurrRegion(event.target.value);
              }}
              error={!currRegion && formError}
            >
              {regionsStore.regions.length ? (
                regionsStore.regions.map((region) => (
                  <MenuItem key={region.id} value={region.id}>
                    {region.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No regions</MenuItem>
              )}
            </TextField>
            {!currRegion && formError && requiredText('Required!')}
          </FormControl>
        </Grid>
        {/* robot current country */}
        <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              required
              disabled={!currRegion}
              id="current-property"
              select
              label="Current property"
              variant="outlined"
              value={currProperty}
              onChange={(event) => {
                setCurrProperty(event.target.value);
              }}
              error={!currProperty && formError}
            >
              {currProperties.length ? (
                currProperties.map((property) => (
                  <MenuItem key={property.id} value={property.id}>
                    {property.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No properties</MenuItem>
              )}
            </TextField>
            {!currProperty && formError && requiredText('Required!')}
          </FormControl>
        </Grid>
      </Grid>
    );
  }
);

export default PortalConfigurations;
