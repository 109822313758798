import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper
} from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons';
import { generateReactComponentKey } from '../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: '100%',
    overflowY: 'auto',
    width: '100%',
    marginTop: '10px'
  },
  table: {
    minWidth: 650,
    border: '2px solid grey',
    borderCollapse: 'collapse'
  },
  cell: {
    padding: theme.spacing(1),
    fontSize: '0.9rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  headerCell: {
    backgroundColor: theme.palette.grey[400],
    fontWeight: 'bold',
    padding: '10px',
    fontSize: '0.9rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  faultedDevice: {
    background: 'red'
  },
  collapseCell: {
    padding: theme.spacing(1),
    fontSize: '0.9rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    paddingBottom: 0,
    paddingTop: 0
  },
  tableHeader: {
    textAlign: 'center',
    margin: '20px 0px'
  }
}));

/**
 * Row - Row component for CAN bus line
 *
 * This component is responsible for rendering rows and managing their
 * states for the CAN bus line table
 * @param {Object} row object containing row data
 * @param {Object} classes CSS styling object
 * @returns
 */
const Row = (props) => {
  const { row, classes } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell className={classes.cell}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className={classes.cell}>
          {row.rxStatus}
        </TableCell>
        <TableCell align="center" className={classes.cell}>
          {row.txStatus}
        </TableCell>
        <TableCell align="center" className={classes.cell}>
          {row.busState}
        </TableCell>
        <TableCell align="center" className={classes.cell}>
          {row.errorsCount}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.collapseCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                CAN bus Errors
              </Typography>
              <Table size="small" aria-label="devicesTable">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerCell}>Error Type</TableCell>
                    <TableCell className={classes.headerCell}>Error Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.errors.map((error) => (
                    <TableRow key={error.id}>
                      <TableCell component="th" scope="row" className={classes.cell}>
                        {error.errorType}
                      </TableCell>
                      <TableCell className={classes.cell}>{error.errorMessage}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

// sample data
const tableRows = [
  {
    rxStatus: 'Healthy',
    txStatus: 'Faulted',
    busState: 'ON',
    errorsCount: 1,
    errors: [{ errorType: 'Generic', errorMessage: 'Some Error Message', id: 1 }]
  }
];

/**
 * CanBusDiagnosticsTable - Table component for CAN bus line
 *
 * This component show CAN bus line diagnostics data in a tabular format
 * @returns {Component} React Component
 */
const CanBusDiagnosticsTable = () => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Typography variant="h4" className={classes.tableHeader}>
        CAN Bus
      </Typography>
      <Table aria-label="collapsible table" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell} />
            <TableCell className={classes.headerCell}>RX Status</TableCell>
            <TableCell align="center" className={classes.headerCell}>
              TX Status
            </TableCell>
            <TableCell align="center" className={classes.headerCell}>
              Bus State
            </TableCell>
            <TableCell align="center" className={classes.headerCell}>
              Errors
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row) => (
            <Row key={generateReactComponentKey()} row={row} classes={classes} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CanBusDiagnosticsTable;
