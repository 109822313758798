import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button
} from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: '100%',
    overflowY: 'auto',
    width: '100%',
    marginTop: '10px'
  },
  table: {
    minWidth: 650,
    border: '2px solid grey',
    borderCollapse: 'separate'
  },
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  cell: {
    padding: theme.spacing(1),
    fontSize: '0.9rem',
    borderBottom: '2px solid #c7c7c7',
    borderRight: '1px solid #e0e0e0'
  },
  headerCell: {
    backgroundColor: theme.palette.grey[400],
    fontWeight: 'bold',
    padding: '10px',
    fontSize: '0.9rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid #e0e0e0'
  },
  collapseButton: {
    backgroundColor: '#d4d2d2',
    marginBottom: '9px',
    marginRight: '10px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#bfbfbf'
    }
  },
  evenRow: {
    backgroundColor: theme.palette.grey[200]
  },
  oddRow: {
    backgroundColor: theme.palette.grey[0]
  },
  tableHeaders: {
    textAlign: 'center',
    margin: '20px 0px'
  },
  errorIcon: {
    marginLeft: 6
  }
}));

/**
 * Row - Row component in CanDevicesTable
 *
 * This component is responsible for rendering individual rows on the table
 * as well as managing their state
 *
 * @param {Object} row - An object containing single row data
 * @param {Object} classes - Css styling classes
 * @param {Boolean} isOpen - Boolean to indicate if row is open
 * @param {Function} toggleRow - Function to handle expanding and closing individual rows
 * @param {Number} index - Row Index
 *
 * @returns {Component} React Component
 */
const Row = ({ row, classes, isOpen, toggleRow, index }) => (
  <>
    <TableRow className={`${classes.root} ${index % 2 === 0 ? classes.evenRow : classes.oddRow}`}>
      <TableCell className={classes.cell}>
        <IconButton aria-label="expand row" size="small" onClick={toggleRow}>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row" className={classes.cell}>
        {row.device}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        {`${row.lastSent}s`}
        {row.lastSent > 10 && <ErrorIcon color="error" fontSize="medium" className={classes.errorIcon} />}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        {`${row.lastReceived}s`}
        {row.lastReceived > 10 && <ErrorIcon color="error" fontSize="medium" className={classes.errorIcon} />}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        {`${row.error}`}
        {row.error > 0 && <ErrorIcon color="error" fontSize="medium" className={classes.errorIcon} />}
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell className={classes.cell} colSpan={6}>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div">
              {`${row.device} Details`}
            </Typography>
            <Table size="small" aria-label="devicesTable">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerCell}>Heartbeat</TableCell>
                  <TableCell className={classes.headerCell}>Heartbeat Details</TableCell>
                  <TableCell className={classes.headerCell}>Health Check</TableCell>
                  <TableCell className={classes.headerCell}>Errors</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.data.map((historyRow) => (
                  <TableRow key={historyRow.id}>
                    <TableCell component="th" scope="row" className={classes.cell}>
                      {historyRow.heartBeat !== 0 ? 'Timed Out' : 'Ok'}
                      {historyRow.heartBeat !== 0 && <ErrorIcon color="error" fontSize="medium" className={classes.errorIcon} />}
                    </TableCell>
                    <TableCell className={classes.cell}>{historyRow.heartbeatDetails}</TableCell>
                    <TableCell className={classes.cell}>
                      {historyRow.healthCheck === 0 ? 'Healthy' : 'Faulted'}
                      {historyRow.healthCheck !== 0 && <ErrorIcon color="error" fontSize="medium" className={classes.errorIcon} />}
                    </TableCell>
                    <TableCell className={classes.cell}>{historyRow.errors}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </>
);

// sample data
const tableRows = [
  {
    device: 'Left MC',
    lastSent: 1.2,
    lastReceived: 2.9,
    error: 0,
    data: [
      {
        id: 1,
        heartBeat: 0,
        heartbeatDetails: 'System is Healthy',
        healthCheck: 0,
        errors: 'NA'
      }
    ]
  },
  {
    device: 'Right MC',
    lastSent: 120,
    lastReceived: 130,
    error: 1,
    data: [
      {
        id: 2,
        heartBeat: 1,
        heartbeatDetails: 'No Heartbeat Received',
        healthCheck: 1,
        errors: 'Some Error Message'
      }
    ]
  }
];

/**
 * CanDevicesTable - Table component for CAN devices
 *
 * This component is responsible for rendering CAN devices
 * diagnostics information in a tabular format
 * @returns
 */
const CanDevicesTable = () => {
  const classes = useStyles();
  const [openStates, setOpenStates] = useState(() => {
    const initialStates = {};
    tableRows.forEach((row) => {
      initialStates[row.device] = false;
    });
    return initialStates;
  });

  /**
   * Function handles toggling rows open or collapse
   * @param {Object} device CAN device object
   */
  const toggleRow = (device) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [device]: !prevState[device]
    }));
  };

  /**
   * Function handles opening and collapsing all rows
   */
  const handleCollapseRows = () => {
    const allOpen = Object.values(openStates).every((state) => state);
    const newOpenStates = {};
    Object.keys(openStates).forEach((key) => {
      newOpenStates[key] = !allOpen;
    });
    setOpenStates(newOpenStates);
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Typography variant="h4" className={classes.tableHeaders}>
        CAN Devices
      </Typography>
      <Button className={classes.collapseButton} onClick={handleCollapseRows}>
        {Object.values(openStates).every((state) => state) ? 'Collapse All' : 'Open All'}
      </Button>
      <Button
        className={classes.collapseButton}
        onClick={() => {
          alert('Work in progress :)');
        }}
      >
        Clear Errors
      </Button>
      <Table aria-label="collapsible table" className={classes.table}>
        <TableHead>
          <TableRow className={classes.root}>
            <TableCell className={classes.headerCell} />
            <TableCell className={classes.headerCell}>Device</TableCell>
            <TableCell align="center" className={classes.headerCell}>
              Last Sent
            </TableCell>
            <TableCell align="center" className={classes.headerCell}>
              Last Received
            </TableCell>
            <TableCell align="center" className={classes.headerCell}>
              Errors
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row, index) => (
            <Row
              key={row.device}
              row={row}
              classes={classes}
              isOpen={openStates[row.device]}
              toggleRow={() => toggleRow(row.device)}
              index={index}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CanDevicesTable;
